/* tslint:disable */
/* eslint-disable */
/**
 * Odyssey API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "./base";

/**
 *
 * @export
 * @interface AcademicYearModel
 */
export class AcademicYearModel {
  /**
   *
   * @type {number}
   * @memberof AcademicYearModel
   */
  id?: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof AcademicYearModel
   */
  text?: string | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof AcademicYearModel
   */
  startYear?: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof AcademicYearModel
   */
  endYear?: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof AcademicYearModel
   */
  firstDayOfApplications?: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof AcademicYearModel
   */
  lastDayOfApplications?: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof AcademicYearModel
   */
  firstDayOfOrientationWeek?: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof AcademicYearModel
   */
  lastDayOfOrientationWeek?: string = undefined as any;

  constructor(data?: Partial<AcademicYearModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 * Application form lookups.
 * @export
 * @interface ApplicationLookups
 */
export class ApplicationLookups {
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof ApplicationLookups
   */
  yearsOfStudy?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof ApplicationLookups
   */
  hoodieSizes?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof ApplicationLookups
   */
  signupSources?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof ApplicationLookups
   */
  courses?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof ApplicationLookups
   */
  trainingSessions?: Array<SimpleLookupModel> | null = undefined as any;

  constructor(data?: Partial<ApplicationLookups>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface ApplicationModel
 */
export class ApplicationModel {
  /**
   * Application step / screen. Used for validation.
   * @type {number}
   * @memberof ApplicationModel
   */
  step?: number = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof ApplicationModel
   */
  acceptedTnC?: boolean = undefined as any;
  /**
   * I understand that I may be requested to undergo Garda Vetting as an S2S volunteer,   and that failure to do so when requested could mean I have to withdraw from the S2S mentor programme.
   * @type {boolean}
   * @memberof ApplicationModel
   */
  acceptedGardaVetting?: boolean = undefined as any;
  /**
   * Will be available for orientation week?
   * @type {boolean}
   * @memberof ApplicationModel
   */
  availableForOrientation?: boolean = undefined as any;
  /**
   * Can commit 3 hours a week to the programme for the full academic year.
   * @type {boolean}
   * @memberof ApplicationModel
   */
  canCommitTo3h?: boolean = undefined as any;
  /**
   * Will complete refresher training within the necessary time period?
   * @type {boolean}
   * @memberof ApplicationModel
   */
  willCompleteRefresherTraining?: boolean = undefined as any;
  /**
   * Is over 18 years of age?
   * @type {boolean}
   * @memberof ApplicationModel
   */
  isOver18?: boolean = undefined as any;
  /**
   * First name.
   * @type {string}
   * @memberof ApplicationModel
   */
  firstName?: string | null = undefined as any;
  /**
   * Last name.
   * @type {string}
   * @memberof ApplicationModel
   */
  lastName?: string | null = undefined as any;
  /**
   * Mobile phone number.
   * @type {string}
   * @memberof ApplicationModel
   */
  phoneNumber?: string | null = undefined as any;
  /**
   * Which course is student taking?
   * @type {number}
   * @memberof ApplicationModel
   */
  courseId?: number = undefined as any;
  /**
   * Year of study expected to be in NEXT year.
   * @type {number}
   * @memberof ApplicationModel
   */
  yearOfStudyId?: number = undefined as any;
  /**
   * Is the student a mature student willing to undertake  work specifically with fellow mature students?
   * @type {boolean}
   * @memberof ApplicationModel
   */
  isMature?: boolean | null = undefined as any;
  /**
   * Is the student prepared to mentor international visiting / Erasmus  students instead of JF students as an S2S mentor?
   * @type {boolean}
   * @memberof ApplicationModel
   */
  isInternational?: boolean | null = undefined as any;
  /**
   * Did the student enter Trinity as an  Access/TAP/HEAR student?
   * @type {boolean}
   * @memberof ApplicationModel
   */
  isAccessTapHear?: boolean | null = undefined as any;
  /**
   * Has the student already trained as S2S mentor?
   * @type {boolean}
   * @memberof ApplicationModel
   */
  isReturning?: boolean | null = undefined as any;
  /**
   * In one or two sentences, reason why they\'d like to be an S2S mentor.
   * @type {string}
   * @memberof ApplicationModel
   */
  reasonForApplication?: string | null = undefined as any;
  /**
   * What one piece of information do they think is most important for a new student?
   * @type {string}
   * @memberof ApplicationModel
   */
  mostImportantNewStudentInfo?: string | null = undefined as any;
  /**
   * Permission to use the above response anonymously on the website?
   * @type {boolean}
   * @memberof ApplicationModel
   */
  responseInfoWaiver?: boolean = undefined as any;
  /**
   * How did they hear about volunteering with S2S?
   * @type {number}
   * @memberof ApplicationModel
   */
  sourceId?: number = undefined as any;
  /**
   * Selected training session.
   * @type {number}
   * @memberof ApplicationModel
   */
  trainingSessionId?: number = undefined as any;
  /**
   * Hoodie size id.
   * @type {number}
   * @memberof ApplicationModel
   */
  hoodieSizeId?: number | null = undefined as any;
  /**
   * Is there anything they would like to change about the mentoring programme?
   * @type {string}
   * @memberof ApplicationModel
   */
  changesToMentorProgramme?: string | null = undefined as any;
  /**
   * Would they like any additional or refresher training?
   * @type {string}
   * @memberof ApplicationModel
   */
  additionalTrainingRequest?: string | null = undefined as any;
  /**
   * Answer to question: Would you like to repeat the in person training (4 hours)?
   * @type {boolean}
   * @memberof ApplicationModel
   */
  repeatInPersonTraining?: boolean = undefined as any;
  /**
   * Answer to question: Would you like to repeat the Blackboard refresher module?
   * @type {boolean}
   * @memberof ApplicationModel
   */
  repeatBlackboardRefresherModule?: boolean = undefined as any;
  /**
   *
   * @type {InvalidModelStateModel}
   * @memberof ApplicationModel
   */
  modelState?: InvalidModelStateModel = undefined as any;

  constructor(data?: Partial<ApplicationModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface AuditLogEventModel
 */
export class AuditLogEventModel {
  /**
   *
   * @type {number}
   * @memberof AuditLogEventModel
   */
  id?: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof AuditLogEventModel
   */
  academicYearId?: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof AuditLogEventModel
   */
  timestamp?: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof AuditLogEventModel
   */
  type?: string | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof AuditLogEventModel
   */
  userFirstName?: string | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof AuditLogEventModel
   */
  userLastName?: string | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof AuditLogEventModel
   */
  data: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof AuditLogEventModel
   */
  forPkId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof AuditLogEventModel
   */
  forUserId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof AuditLogEventModel
   */
  typeId?: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof AuditLogEventModel
   */
  duration?: number | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof AuditLogEventModel
   */
  isDeletable?: boolean = undefined as any;
  /**
   *
   * @type {InvalidModelStateModel}
   * @memberof AuditLogEventModel
   */
  modelState?: InvalidModelStateModel = undefined as any;

  constructor(data?: Partial<AuditLogEventModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface AuditLogEventModelGridCollection
 */
export class AuditLogEventModelGridCollection {
  /**
   * Currently fetched page of items.
   * @type {Array<AuditLogEventModel>}
   * @memberof AuditLogEventModelGridCollection
   */
  items?: Array<AuditLogEventModel> | null = undefined as any;
  /**
   * Current page number of the fetched items in the DB.
   * @type {number}
   * @memberof AuditLogEventModelGridCollection
   */
  currentPage?: number = undefined as any;
  /**
   * Fetched page size.
   * @type {number}
   * @memberof AuditLogEventModelGridCollection
   */
  pageSize?: number = undefined as any;
  /**
   * Total amount of pages of filtered content.
   * @type {number}
   * @memberof AuditLogEventModelGridCollection
   */
  pageCount?: number = undefined as any;
  /**
   * Total amount of items in the DB.
   * @type {number}
   * @memberof AuditLogEventModelGridCollection
   */
  totalItemCount?: number = undefined as any;

  constructor(data?: Partial<AuditLogEventModelGridCollection>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface AuditRecordFilterModel
 */
export class AuditRecordFilterModel {
  /**
   *
   * @type {number}
   * @memberof AuditRecordFilterModel
   */
  academicYearId?: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof AuditRecordFilterModel
   */
  forPkId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof AuditRecordFilterModel
   */
  forUserId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof AuditRecordFilterModel
   */
  auditRecordTypeId?: number | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof AuditRecordFilterModel
   */
  hideMinorEvents?: boolean = undefined as any;
  /**
   * Page to fetch.
   * @type {number}
   * @memberof AuditRecordFilterModel
   */
  page?: number | null = undefined as any;
  /**
   * Amount of items to fetch.
   * @type {number}
   * @memberof AuditRecordFilterModel
   */
  pageSize?: number | null = undefined as any;
  /**
   * Sort by KEY (entity field name).
   * @type {string}
   * @memberof AuditRecordFilterModel
   */
  sortBy?: string | null = undefined as any;
  /**
   * Sort order.  Allowed: \"ascending\" / \"asc\" / \"descending\" / \"desc\".
   * @type {string}
   * @memberof AuditRecordFilterModel
   */
  sortOrder?: string | null = undefined as any;
  /**
   * Include soft deleted entities?
   * @type {boolean}
   * @memberof AuditRecordFilterModel
   */
  includeDeleted?: boolean | null = undefined as any;
  /**
   * User Id - typically owner id.
   * @type {number}
   * @memberof AuditRecordFilterModel
   */
  userId?: number | null = undefined as any;
  /**
   * Search phrase.
   * @type {string}
   * @memberof AuditRecordFilterModel
   */
  search?: string | null = undefined as any;

  constructor(data?: Partial<AuditRecordFilterModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface AzureFirewallAddRule
 */
export class AzureFirewallAddRule {
  /**
   *
   * @type {string}
   * @memberof AzureFirewallAddRule
   */
  name: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof AzureFirewallAddRule
   */
  ipAddress: string = undefined as any;
  /**
   *
   * @type {InvalidModelStateModel}
   * @memberof AzureFirewallAddRule
   */
  modelState?: InvalidModelStateModel = undefined as any;

  constructor(data?: Partial<AzureFirewallAddRule>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface AzureFirewallRuleModel
 */
export class AzureFirewallRuleModel {
  /**
   * Name of rule.
   * @type {string}
   * @memberof AzureFirewallRuleModel
   */
  name?: string | null = undefined as any;
  /**
   *
   * @type {AzureFirewallRuleProperties}
   * @memberof AzureFirewallRuleModel
   */
  properties?: AzureFirewallRuleProperties = undefined as any;

  constructor(data?: Partial<AzureFirewallRuleModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface AzureFirewallRuleProperties
 */
export class AzureFirewallRuleProperties {
  /**
   * Start IP address.
   * @type {string}
   * @memberof AzureFirewallRuleProperties
   */
  startIpAddress?: string | null = undefined as any;
  /**
   * End IP address.
   * @type {string}
   * @memberof AzureFirewallRuleProperties
   */
  endIpAddress?: string | null = undefined as any;

  constructor(data?: Partial<AzureFirewallRuleProperties>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface AzureFirewallRuleVm
 */
export class AzureFirewallRuleVm {
  /**
   * List of firewall rule models.
   * @type {Array<AzureFirewallRuleModel>}
   * @memberof AzureFirewallRuleVm
   */
  rules?: Array<AzureFirewallRuleModel> | null = undefined as any;

  constructor(data?: Partial<AzureFirewallRuleVm>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface CreateMentorGroupModel
 */
export class CreateMentorGroupModel {
  /**
   * Mentor group name (i.e. 2122-BULG-03).
   * @type {string}
   * @memberof CreateMentorGroupModel
   */
  name: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CreateMentorGroupModel
   */
  academicYearId?: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof CreateMentorGroupModel
   */
  courseId?: number = undefined as any;
  /**
   *
   * @type {InvalidModelStateModel}
   * @memberof CreateMentorGroupModel
   */
  modelState?: InvalidModelStateModel = undefined as any;

  constructor(data?: Partial<CreateMentorGroupModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface DashboardItemsModel
 */
export class DashboardItemsModel {
  /**
   *
   * @type {number}
   * @memberof DashboardItemsModel
   */
  groupId?: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof DashboardItemsModel
   */
  groupName?: string | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof DashboardItemsModel
   */
  count?: number = undefined as any;

  constructor(data?: Partial<DashboardItemsModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface DashboardLookups
 */
export class DashboardLookups {
  /**
   *
   * @type {Array<AcademicYearModel>}
   * @memberof DashboardLookups
   */
  academicYears?: Array<AcademicYearModel> | null = undefined as any;

  constructor(data?: Partial<DashboardLookups>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface DashboardModel
 */
export class DashboardModel {
  /**
   *
   * @type {Array<DashboardItemsModel>}
   * @memberof DashboardModel
   */
  applicants?: Array<DashboardItemsModel> | null = undefined as any;

  constructor(data?: Partial<DashboardModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 * Email message model describes message recipients, subject and content.
 * @export
 * @interface EmailMessageModel
 */
export class EmailMessageModel {
  /**
   * Email sender display name.
   * @type {string}
   * @memberof EmailMessageModel
   */
  fromDisplayName?: string | null = undefined as any;
  /**
   * List of email recipients.
   * @type {Array<string>}
   * @memberof EmailMessageModel
   */
  to?: Array<string> | null = undefined as any;
  /**
   * List of blind carbon copy recipients.
   * @type {Array<string>}
   * @memberof EmailMessageModel
   */
  bcc?: Array<string> | null = undefined as any;
  /**
   * Email subject.
   * @type {string}
   * @memberof EmailMessageModel
   */
  subject?: string | null = undefined as any;
  /**
   * Email contentType.
   * @type {string}
   * @memberof EmailMessageModel
   */
  contentType?: string | null = undefined as any;
  /**
   * Email content.
   * @type {string}
   * @memberof EmailMessageModel
   */
  body?: string | null = undefined as any;

  constructor(data?: Partial<EmailMessageModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 * Grid filter model. This class is supposed to be used on its own for the most basic  grid filtering needs. Otherwise, if more grid filtering params are required, it can  be inherited from and extended with extra filtering fields.                All of these params are optional.
 * @export
 * @interface GridFilter
 */
export class GridFilter {
  /**
   * Page to fetch.
   * @type {number}
   * @memberof GridFilter
   */
  page?: number | null = undefined as any;
  /**
   * Amount of items to fetch.
   * @type {number}
   * @memberof GridFilter
   */
  pageSize?: number | null = undefined as any;
  /**
   * Sort by KEY (entity field name).
   * @type {string}
   * @memberof GridFilter
   */
  sortBy?: string | null = undefined as any;
  /**
   * Sort order.  Allowed: \"ascending\" / \"asc\" / \"descending\" / \"desc\".
   * @type {string}
   * @memberof GridFilter
   */
  sortOrder?: string | null = undefined as any;
  /**
   * Include soft deleted entities?
   * @type {boolean}
   * @memberof GridFilter
   */
  includeDeleted?: boolean | null = undefined as any;
  /**
   * User Id - typically owner id.
   * @type {number}
   * @memberof GridFilter
   */
  userId?: number | null = undefined as any;
  /**
   * Search phrase.
   * @type {string}
   * @memberof GridFilter
   */
  search?: string | null = undefined as any;

  constructor(data?: Partial<GridFilter>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 * Invalid ModelState model - represents a simplified version of  ModelState object. It only contains ModelState errors.
 * @export
 * @interface InvalidModelStateModel
 */
export class InvalidModelStateModel {
  /**
   * A collection errors (values) for validated model properties (keys).
   * @type {{ [key: string]: Array<string>; }}
   * @memberof InvalidModelStateModel
   */
  modelState?: { [key: string]: Array<string> } | null = undefined as any;

  constructor(data?: Partial<InvalidModelStateModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface LastActivityHoursModel
 */
export class LastActivityHoursModel {
  /**
   *
   * @type {number}
   * @memberof LastActivityHoursModel
   */
  hoursThisWeek?: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof LastActivityHoursModel
   */
  hoursThisMonth?: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof LastActivityHoursModel
   */
  hoursThisAcademicYear?: number = undefined as any;

  constructor(data?: Partial<LastActivityHoursModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 * User login POST model.
 * @export
 * @interface LoginModel
 */
export class LoginModel {
  /**
   * Microsoft auth token
   * @type {string}
   * @memberof LoginModel
   */
  token: string = undefined as any;

  constructor(data?: Partial<LoginModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface MenteeFilterModel
 */
export class MenteeFilterModel {
  /**
   *
   * @type {number}
   * @memberof MenteeFilterModel
   */
  academicYearId?: number = undefined as any;
  /**
   * Page to fetch.
   * @type {number}
   * @memberof MenteeFilterModel
   */
  page?: number | null = undefined as any;
  /**
   * Amount of items to fetch.
   * @type {number}
   * @memberof MenteeFilterModel
   */
  pageSize?: number | null = undefined as any;
  /**
   * Sort by KEY (entity field name).
   * @type {string}
   * @memberof MenteeFilterModel
   */
  sortBy?: string | null = undefined as any;
  /**
   * Sort order.  Allowed: \"ascending\" / \"asc\" / \"descending\" / \"desc\".
   * @type {string}
   * @memberof MenteeFilterModel
   */
  sortOrder?: string | null = undefined as any;
  /**
   * Include soft deleted entities?
   * @type {boolean}
   * @memberof MenteeFilterModel
   */
  includeDeleted?: boolean | null = undefined as any;
  /**
   * User Id - typically owner id.
   * @type {number}
   * @memberof MenteeFilterModel
   */
  userId?: number | null = undefined as any;
  /**
   * Search phrase.
   * @type {string}
   * @memberof MenteeFilterModel
   */
  search?: string | null = undefined as any;

  constructor(data?: Partial<MenteeFilterModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface MenteeLookups
 */
export class MenteeLookups {
  /**
   *
   * @type {Array<AcademicYearModel>}
   * @memberof MenteeLookups
   */
  academicYears?: Array<AcademicYearModel> | null = undefined as any;

  constructor(data?: Partial<MenteeLookups>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface MenteeModel
 */
export class MenteeModel {
  /**
   *
   * @type {number}
   * @memberof MenteeModel
   */
  id?: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof MenteeModel
   */
  firstName?: string | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof MenteeModel
   */
  lastName?: string | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof MenteeModel
   */
  email?: string | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof MenteeModel
   */
  course?: string | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof MenteeModel
   */
  mentorGroup?: string | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof MenteeModel
   */
  mentorGroupId?: number = undefined as any;

  constructor(data?: Partial<MenteeModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface MenteeModelGridCollection
 */
export class MenteeModelGridCollection {
  /**
   * Currently fetched page of items.
   * @type {Array<MenteeModel>}
   * @memberof MenteeModelGridCollection
   */
  items?: Array<MenteeModel> | null = undefined as any;
  /**
   * Current page number of the fetched items in the DB.
   * @type {number}
   * @memberof MenteeModelGridCollection
   */
  currentPage?: number = undefined as any;
  /**
   * Fetched page size.
   * @type {number}
   * @memberof MenteeModelGridCollection
   */
  pageSize?: number = undefined as any;
  /**
   * Total amount of pages of filtered content.
   * @type {number}
   * @memberof MenteeModelGridCollection
   */
  pageCount?: number = undefined as any;
  /**
   * Total amount of items in the DB.
   * @type {number}
   * @memberof MenteeModelGridCollection
   */
  totalItemCount?: number = undefined as any;

  constructor(data?: Partial<MenteeModelGridCollection>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface MentorCollectionModel
 */
export class MentorCollectionModel {
  /**
   *
   * @type {number}
   * @memberof MentorCollectionModel
   */
  id?: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof MentorCollectionModel
   */
  firstName?: string | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof MentorCollectionModel
   */
  lastName?: string | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof MentorCollectionModel
   */
  course?: string | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof MentorCollectionModel
   */
  year?: string | null = undefined as any;
  /**
   *
   * @type {Array<string>}
   * @memberof MentorCollectionModel
   */
  buddyPrefs?: Array<string> | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof MentorCollectionModel
   */
  group?: string | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof MentorCollectionModel
   */
  applicationStatus?: string | null = undefined as any;

  constructor(data?: Partial<MentorCollectionModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface MentorGroupMemberModel
 */
export class MentorGroupMemberModel {
  /**
   * Mentor / Student.
   * @type {string}
   * @memberof MentorGroupMemberModel
   */
  role?: string | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof MentorGroupMemberModel
   */
  fullName?: string | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof MentorGroupMemberModel
   */
  email?: string | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof MentorGroupMemberModel
   */
  course?: string | null = undefined as any;

  constructor(data?: Partial<MentorGroupMemberModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface MentorGroupMentorModel
 */
export class MentorGroupMentorModel {
  /**
   * Mentor Id.
   * @type {number}
   * @memberof MentorGroupMentorModel
   */
  id?: number = undefined as any;
  /**
   * Mentor first name.
   * @type {string}
   * @memberof MentorGroupMentorModel
   */
  firstName?: string | null = undefined as any;
  /**
   * Mentor last name.
   * @type {string}
   * @memberof MentorGroupMentorModel
   */
  lastName?: string | null = undefined as any;
  /**
   * Course the volunteer is taking.
   * @type {string}
   * @memberof MentorGroupMentorModel
   */
  course?: string | null = undefined as any;
  /**
   * TSM Subject 1.
   * @type {string}
   * @memberof MentorGroupMentorModel
   */
  subject1?: string | null = undefined as any;
  /**
   * TSM Subject 2.
   * @type {string}
   * @memberof MentorGroupMentorModel
   */
  subject2?: string | null = undefined as any;
  /**
   * Year of study.
   * @type {string}
   * @memberof MentorGroupMentorModel
   */
  year?: string | null = undefined as any;
  /**
   * Other mentors selected as buddy preferences to be paired up with.
   * @type {Array<string>}
   * @memberof MentorGroupMentorModel
   */
  buddyPrefs?: Array<string> | null = undefined as any;

  constructor(data?: Partial<MentorGroupMentorModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface MentorGroupModel
 */
export class MentorGroupModel {
  /**
   * Mentor group Id.
   * @type {number}
   * @memberof MentorGroupModel
   */
  id?: number = undefined as any;
  /**
   * Mentor group name (i.e. 2122-BULG-03).
   * @type {string}
   * @memberof MentorGroupModel
   */
  name?: string | null = undefined as any;
  /**
   * Mentors (volunteers).
   * @type {Array<MentorGroupMentorModel>}
   * @memberof MentorGroupModel
   */
  mentors?: Array<MentorGroupMentorModel> | null = undefined as any;

  constructor(data?: Partial<MentorGroupModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface MentorGroupVolunteerModel
 */
export class MentorGroupVolunteerModel {
  /**
   * Mentor group Id.
   * @type {number}
   * @memberof MentorGroupVolunteerModel
   */
  id?: number = undefined as any;
  /**
   * Mentor group name (i.e. 2122-BULG-03).
   * @type {string}
   * @memberof MentorGroupVolunteerModel
   */
  name?: string | null = undefined as any;
  /**
   * Mentors (volunteers).
   * @type {number}
   * @memberof MentorGroupVolunteerModel
   */
  mentors?: number | null = undefined as any;
  /**
   * Academic year Id.
   * @type {number}
   * @memberof MentorGroupVolunteerModel
   */
  yearId?: number = undefined as any;
  /**
   * Course Id.
   * @type {number}
   * @memberof MentorGroupVolunteerModel
   */
  courseId?: number = undefined as any;

  constructor(data?: Partial<MentorGroupVolunteerModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface MentorGroupsFilter
 */
export class MentorGroupsFilter {
  /**
   * Academic year Id.
   * @type {number}
   * @memberof MentorGroupsFilter
   */
  yearId?: number = undefined as any;
  /**
   * Course Id.
   * @type {number}
   * @memberof MentorGroupsFilter
   */
  courseId?: number | null = undefined as any;
  /**
   * Number of mentors.
   * @type {number}
   * @memberof MentorGroupsFilter
   */
  mentors?: number | null = undefined as any;

  constructor(data?: Partial<MentorGroupsFilter>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface MentorGroupsLookups
 */
export class MentorGroupsLookups {
  /**
   *
   * @type {Array<AcademicYearModel>}
   * @memberof MentorGroupsLookups
   */
  academicYears?: Array<AcademicYearModel> | null = undefined as any;
  /**
   *
   * @type {Array<NullableLookupModel>}
   * @memberof MentorGroupsLookups
   */
  courses?: Array<NullableLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof MentorGroupsLookups
   */
  tags?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<NumberOfMentorsModel>}
   * @memberof MentorGroupsLookups
   */
  noOfMentors?: Array<NumberOfMentorsModel> | null = undefined as any;

  constructor(data?: Partial<MentorGroupsLookups>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface MentorGroupsStatsFilter
 */
export class MentorGroupsStatsFilter {
  /**
   * Academic year Id.
   * @type {number}
   * @memberof MentorGroupsStatsFilter
   */
  yearId?: number = undefined as any;
  /**
   * Course Id.
   * @type {number}
   * @memberof MentorGroupsStatsFilter
   */
  courseId?: number = undefined as any;

  constructor(data?: Partial<MentorGroupsStatsFilter>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 * Current mentor groups statistics.
 * @export
 * @interface MentorGroupsStatsModel
 */
export class MentorGroupsStatsModel {
  /**
   * Groups with 0 mentors.
   * @type {number}
   * @memberof MentorGroupsStatsModel
   */
  with0Mentors?: number = undefined as any;
  /**
   * Groups with 1 mentor.
   * @type {number}
   * @memberof MentorGroupsStatsModel
   */
  with1Mentors?: number = undefined as any;
  /**
   * Groups with 2 mentors.
   * @type {number}
   * @memberof MentorGroupsStatsModel
   */
  with2Mentors?: number = undefined as any;
  /**
   * Groups with 3 mentors.
   * @type {number}
   * @memberof MentorGroupsStatsModel
   */
  with3Mentors?: number = undefined as any;
  /**
   * Groups with 4 or more mentors.
   * @type {number}
   * @memberof MentorGroupsStatsModel
   */
  with4OrMoreMentors?: number = undefined as any;

  constructor(data?: Partial<MentorGroupsStatsModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface MentorsFilter
 */
export class MentorsFilter {
  /**
   * Course Id.
   * @type {number}
   * @memberof MentorsFilter
   */
  courseId?: number | null = undefined as any;
  /**
   * Academic year Id.
   * @type {number}
   * @memberof MentorsFilter
   */
  academicYearId?: number = undefined as any;
  /**
   * Search by name.
   * @type {string}
   * @memberof MentorsFilter
   */
  search?: string | null = undefined as any;
  /**
   * Only show volunteers not already in a group?
   * @type {boolean}
   * @memberof MentorsFilter
   */
  onlyShowNotInGroup?: boolean = undefined as any;
  /**
   * Min number of mentors in group.
   * @type {number}
   * @memberof MentorsFilter
   */
  minMentors?: number | null = undefined as any;
  /**
   *
   * @type {Array<number>}
   * @memberof MentorsFilter
   */
  tagIds?: Array<number> | null = undefined as any;

  constructor(data?: Partial<MentorsFilter>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface MyBuddiesFormModel
 */
export class MyBuddiesFormModel {
  /**
   *
   * @type {boolean}
   * @memberof MyBuddiesFormModel
   */
  canSelectBuddy?: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof MyBuddiesFormModel
   */
  isInAnyMentorGroup?: boolean = undefined as any;
  /**
   *
   * @type {Array<NullableLookupModel>}
   * @memberof MyBuddiesFormModel
   */
  mentors?: Array<NullableLookupModel> | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof MyBuddiesFormModel
   */
  buddy1VolunteerId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof MyBuddiesFormModel
   */
  buddy2VolunteerId?: number | null = undefined as any;

  constructor(data?: Partial<MyBuddiesFormModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface MyBuddiesPostModel
 */
export class MyBuddiesPostModel {
  /**
   *
   * @type {number}
   * @memberof MyBuddiesPostModel
   */
  academicYearId?: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof MyBuddiesPostModel
   */
  buddy1VolunteerId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof MyBuddiesPostModel
   */
  buddy2VolunteerId?: number | null = undefined as any;

  constructor(data?: Partial<MyBuddiesPostModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface MyMentorGroupModel
 */
export class MyMentorGroupModel {
  /**
   *
   * @type {boolean}
   * @memberof MyMentorGroupModel
   */
  isApproved?: boolean = undefined as any;
  /**
   *
   * @type {string}
   * @memberof MyMentorGroupModel
   */
  groupName?: string | null = undefined as any;
  /**
   *
   * @type {Array<MentorGroupMemberModel>}
   * @memberof MyMentorGroupModel
   */
  members?: Array<MentorGroupMemberModel> | null = undefined as any;

  constructor(data?: Partial<MyMentorGroupModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 * Lookup model for use in something like dropdown which should have an \"Any\"  option which basically means an absence of the Id.
 * @export
 * @interface NullableLookupModel
 */
export class NullableLookupModel {
  /**
   *
   * @type {number}
   * @memberof NullableLookupModel
   */
  id?: number | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof NullableLookupModel
   */
  text?: string | null = undefined as any;

  constructor(data?: Partial<NullableLookupModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface NumberOfMentorsModel
 */
export class NumberOfMentorsModel {
  /**
   *
   * @type {string}
   * @memberof NumberOfMentorsModel
   */
  text?: string | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof NumberOfMentorsModel
   */
  amount?: number | null = undefined as any;

  constructor(data?: Partial<NumberOfMentorsModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface PrintoutBatchGroupModel
 */
export class PrintoutBatchGroupModel {
  /**
   *
   * @type {number}
   * @memberof PrintoutBatchGroupModel
   */
  id?: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof PrintoutBatchGroupModel
   */
  groupName?: string | null = undefined as any;
  /**
   *
   * @type {Array<MentorGroupMemberModel>}
   * @memberof PrintoutBatchGroupModel
   */
  members?: Array<MentorGroupMemberModel> | null = undefined as any;

  constructor(data?: Partial<PrintoutBatchGroupModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface SimpleLookupModel
 */
export class SimpleLookupModel {
  /**
   *
   * @type {number}
   * @memberof SimpleLookupModel
   */
  id?: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof SimpleLookupModel
   */
  text?: string | null = undefined as any;

  constructor(data?: Partial<SimpleLookupModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface TagCollectionItemModel
 */
export class TagCollectionItemModel {
  /**
   * Tag id.
   * @type {number}
   * @memberof TagCollectionItemModel
   */
  id?: number = undefined as any;
  /**
   * Tag name.
   * @type {string}
   * @memberof TagCollectionItemModel
   */
  name?: string | null = undefined as any;
  /**
   * How many times has this tag been associated with a volunteer?
   * @type {number}
   * @memberof TagCollectionItemModel
   */
  volunteerTagCount?: number = undefined as any;

  constructor(data?: Partial<TagCollectionItemModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 * Access token response model.
 * @export
 * @interface TokenResponse
 */
export class TokenResponse {
  /**
   * Raw, encoded access token.
   * @type {string}
   * @memberof TokenResponse
   */
  accessToken?: string | null = undefined as any;
  /**
   * Refresh token (not in use ATM).
   * @type {string}
   * @memberof TokenResponse
   */
  refreshToken?: string | null = undefined as any;
  /**
   * Access token type.
   * @type {string}
   * @memberof TokenResponse
   */
  tokenType?: string | null = undefined as any;
  /**
   * Time span of token validity (in seconds).
   * @type {number}
   * @memberof TokenResponse
   */
  validFor?: number = undefined as any;

  constructor(data?: Partial<TokenResponse>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface TrainingRecordModel
 */
export class TrainingRecordModel {
  /**
   *
   * @type {number}
   * @memberof TrainingRecordModel
   */
  trainingRecordId?: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof TrainingRecordModel
   */
  volunteerId?: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof TrainingRecordModel
   */
  userId?: number | null = undefined as any;
  /**
   * First name.
   * @type {string}
   * @memberof TrainingRecordModel
   */
  firstName?: string | null = undefined as any;
  /**
   * Last name.
   * @type {string}
   * @memberof TrainingRecordModel
   */
  lastName?: string | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof TrainingRecordModel
   */
  volunteerNumber?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof TrainingRecordModel
   */
  trainingSessionId?: number = undefined as any;

  constructor(data?: Partial<TrainingRecordModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface TrainingSessionAttendenceLookups
 */
export class TrainingSessionAttendenceLookups {
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof TrainingSessionAttendenceLookups
   */
  trainingRecordStatuses?: Array<SimpleLookupModel> | null = undefined as any;

  constructor(data?: Partial<TrainingSessionAttendenceLookups>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface TrainingSessionModel
 */
export class TrainingSessionModel {
  /**
   *
   * @type {number}
   * @memberof TrainingSessionModel
   */
  id?: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof TrainingSessionModel
   */
  startDateTime?: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof TrainingSessionModel
   */
  endDateTime?: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof TrainingSessionModel
   */
  capacity?: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof TrainingSessionModel
   */
  bookings?: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof TrainingSessionModel
   */
  academicYearId?: number = undefined as any;
  /**
   *
   * @type {SimpleLookupModel}
   * @memberof TrainingSessionModel
   */
  venue?: SimpleLookupModel = undefined as any;
  /**
   *
   * @type {SimpleLookupModel}
   * @memberof TrainingSessionModel
   */
  trainingType?: SimpleLookupModel = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof TrainingSessionModel
   */
  isCancelled?: boolean = undefined as any;

  constructor(data?: Partial<TrainingSessionModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface TrainingSessionModelGridCollection
 */
export class TrainingSessionModelGridCollection {
  /**
   * Currently fetched page of items.
   * @type {Array<TrainingSessionModel>}
   * @memberof TrainingSessionModelGridCollection
   */
  items?: Array<TrainingSessionModel> | null = undefined as any;
  /**
   * Current page number of the fetched items in the DB.
   * @type {number}
   * @memberof TrainingSessionModelGridCollection
   */
  currentPage?: number = undefined as any;
  /**
   * Fetched page size.
   * @type {number}
   * @memberof TrainingSessionModelGridCollection
   */
  pageSize?: number = undefined as any;
  /**
   * Total amount of pages of filtered content.
   * @type {number}
   * @memberof TrainingSessionModelGridCollection
   */
  pageCount?: number = undefined as any;
  /**
   * Total amount of items in the DB.
   * @type {number}
   * @memberof TrainingSessionModelGridCollection
   */
  totalItemCount?: number = undefined as any;

  constructor(data?: Partial<TrainingSessionModelGridCollection>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface TrainingSessionPrintSignInSheetModel
 */
export class TrainingSessionPrintSignInSheetModel {
  /**
   *
   * @type {number}
   * @memberof TrainingSessionPrintSignInSheetModel
   */
  trainingSessionId?: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof TrainingSessionPrintSignInSheetModel
   */
  startDateTime?: string = undefined as any;
  /**
   *
   * @type {SimpleLookupModel}
   * @memberof TrainingSessionPrintSignInSheetModel
   */
  venue?: SimpleLookupModel = undefined as any;
  /**
   *
   * @type {Array<TrainingRecordModel>}
   * @memberof TrainingSessionPrintSignInSheetModel
   */
  trainingRecords?: Array<TrainingRecordModel> | null = undefined as any;

  constructor(data?: Partial<TrainingSessionPrintSignInSheetModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface TrainingSessionSaveLookups
 */
export class TrainingSessionSaveLookups {
  /**
   *
   * @type {Array<AcademicYearModel>}
   * @memberof TrainingSessionSaveLookups
   */
  academicYears?: Array<AcademicYearModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof TrainingSessionSaveLookups
   */
  venues?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof TrainingSessionSaveLookups
   */
  trainingTypes?: Array<SimpleLookupModel> | null = undefined as any;

  constructor(data?: Partial<TrainingSessionSaveLookups>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface TrainingSessionSaveModel
 */
export class TrainingSessionSaveModel {
  /**
   *
   * @type {number}
   * @memberof TrainingSessionSaveModel
   */
  id?: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof TrainingSessionSaveModel
   */
  startDateTime: string = undefined as any;
  /**
   *
   * @type {number}
   * @memberof TrainingSessionSaveModel
   */
  capacity?: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof TrainingSessionSaveModel
   */
  academicYearId?: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof TrainingSessionSaveModel
   */
  venueId?: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof TrainingSessionSaveModel
   */
  trainingTypeId?: number = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof TrainingSessionSaveModel
   */
  isCancelled?: boolean = undefined as any;
  /**
   *
   * @type {InvalidModelStateModel}
   * @memberof TrainingSessionSaveModel
   */
  modelState?: InvalidModelStateModel = undefined as any;

  constructor(data?: Partial<TrainingSessionSaveModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface TrainingSessionSearchFilterModel
 */
export class TrainingSessionSearchFilterModel {
  /**
   *
   * @type {number}
   * @memberof TrainingSessionSearchFilterModel
   */
  academicYearId?: number = undefined as any;
  /**
   * Page to fetch.
   * @type {number}
   * @memberof TrainingSessionSearchFilterModel
   */
  page?: number | null = undefined as any;
  /**
   * Amount of items to fetch.
   * @type {number}
   * @memberof TrainingSessionSearchFilterModel
   */
  pageSize?: number | null = undefined as any;
  /**
   * Sort by KEY (entity field name).
   * @type {string}
   * @memberof TrainingSessionSearchFilterModel
   */
  sortBy?: string | null = undefined as any;
  /**
   * Sort order.  Allowed: \"ascending\" / \"asc\" / \"descending\" / \"desc\".
   * @type {string}
   * @memberof TrainingSessionSearchFilterModel
   */
  sortOrder?: string | null = undefined as any;
  /**
   * Include soft deleted entities?
   * @type {boolean}
   * @memberof TrainingSessionSearchFilterModel
   */
  includeDeleted?: boolean | null = undefined as any;
  /**
   * User Id - typically owner id.
   * @type {number}
   * @memberof TrainingSessionSearchFilterModel
   */
  userId?: number | null = undefined as any;
  /**
   * Search phrase.
   * @type {string}
   * @memberof TrainingSessionSearchFilterModel
   */
  search?: string | null = undefined as any;

  constructor(data?: Partial<TrainingSessionSearchFilterModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface TrainingSessionSearchLookups
 */
export class TrainingSessionSearchLookups {
  /**
   *
   * @type {Array<AcademicYearModel>}
   * @memberof TrainingSessionSearchLookups
   */
  academicYears?: Array<AcademicYearModel> | null = undefined as any;

  constructor(data?: Partial<TrainingSessionSearchLookups>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface UpdateMentorGroupModel
 */
export class UpdateMentorGroupModel {
  /**
   *
   * @type {number}
   * @memberof UpdateMentorGroupModel
   */
  id?: number = undefined as any;
  /**
   * Mentor group name (i.e. 2122-BULG-03).
   * @type {string}
   * @memberof UpdateMentorGroupModel
   */
  name: string = undefined as any;
  /**
   *
   * @type {InvalidModelStateModel}
   * @memberof UpdateMentorGroupModel
   */
  modelState?: InvalidModelStateModel = undefined as any;

  constructor(data?: Partial<UpdateMentorGroupModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 * Application form lookups.
 * @export
 * @interface UserLookups
 */
export class UserLookups {
  /**
   *
   * @type {Array<AcademicYearModel>}
   * @memberof UserLookups
   */
  academicYears?: Array<AcademicYearModel> | null = undefined as any;

  constructor(data?: Partial<UserLookups>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface UserModel
 */
export class UserModel {
  /**
   * User Id.
   * @type {number}
   * @memberof UserModel
   */
  userId?: number = undefined as any;
  /**
   * UserName.
   * @type {string}
   * @memberof UserModel
   */
  userName?: string | null = undefined as any;
  /**
   * First name.
   * @type {string}
   * @memberof UserModel
   */
  firstName?: string | null = undefined as any;
  /**
   * Last name.
   * @type {string}
   * @memberof UserModel
   */
  lastName?: string | null = undefined as any;
  /**
   * Phone number.
   * @type {string}
   * @memberof UserModel
   */
  phoneNumber?: string | null = undefined as any;
  /**
   * User email.
   * @type {string}
   * @memberof UserModel
   */
  email?: string | null = undefined as any;
  /**
   * Is email confirmed?
   * @type {boolean}
   * @memberof UserModel
   */
  emailConfirmed?: boolean = undefined as any;
  /**
   * Date of user creation (registration date).
   * @type {string}
   * @memberof UserModel
   */
  dateCreated?: string = undefined as any;
  /**
   * Date of last user activity (login or page refresh).
   * @type {string}
   * @memberof UserModel
   */
  lastActive?: string = undefined as any;
  /**
   * List of user roles the user belongs to.
   * @type {Array<string>}
   * @memberof UserModel
   */
  roles?: Array<string> | null = undefined as any;
  /**
   * Is user in Admin role?
   * @type {boolean}
   * @memberof UserModel
   */
  isAdmin?: boolean = undefined as any;
  /**
   * Users IP address.
   * @type {string}
   * @memberof UserModel
   */
  ipAddress?: string | null = undefined as any;
  /**
   * Volunteer number.
   * @type {number}
   * @memberof UserModel
   */
  volunteerNumber?: number | null = undefined as any;
  /**
   *
   * @type {InvalidModelStateModel}
   * @memberof UserModel
   */
  modelState?: InvalidModelStateModel = undefined as any;

  constructor(data?: Partial<UserModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface UserModelGridCollection
 */
export class UserModelGridCollection {
  /**
   * Currently fetched page of items.
   * @type {Array<UserModel>}
   * @memberof UserModelGridCollection
   */
  items?: Array<UserModel> | null = undefined as any;
  /**
   * Current page number of the fetched items in the DB.
   * @type {number}
   * @memberof UserModelGridCollection
   */
  currentPage?: number = undefined as any;
  /**
   * Fetched page size.
   * @type {number}
   * @memberof UserModelGridCollection
   */
  pageSize?: number = undefined as any;
  /**
   * Total amount of pages of filtered content.
   * @type {number}
   * @memberof UserModelGridCollection
   */
  pageCount?: number = undefined as any;
  /**
   * Total amount of items in the DB.
   * @type {number}
   * @memberof UserModelGridCollection
   */
  totalItemCount?: number = undefined as any;

  constructor(data?: Partial<UserModelGridCollection>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface UserTrainingModel
 */
export class UserTrainingModel {
  /**
   * Id of the training session associated with the latest training record.
   * @type {number}
   * @memberof UserTrainingModel
   */
  latestTrainingSessionId?: number | null = undefined as any;
  /**
   * Time block of the latest training session start / end date time block.
   * @type {string}
   * @memberof UserTrainingModel
   */
  latestTrainingSessionTimeBlock?: string | null = undefined as any;
  /**
   * Available training sessions to change to.
   * @type {Array<SimpleLookupModel>}
   * @memberof UserTrainingModel
   */
  availableSessions?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   * Previous training records.
   * @type {Array<UserTrainingRecordModel>}
   * @memberof UserTrainingModel
   */
  trainingRecords?: Array<UserTrainingRecordModel> | null = undefined as any;

  constructor(data?: Partial<UserTrainingModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface UserTrainingRecordModel
 */
export class UserTrainingRecordModel {
  /**
   * Training record.
   * @type {number}
   * @memberof UserTrainingRecordModel
   */
  trainingRecordId?: number = undefined as any;
  /**
   * Session start / end date time block.
   * @type {string}
   * @memberof UserTrainingRecordModel
   */
  sessionTimeBlock?: string | null = undefined as any;
  /**
   * Training record status id.
   * @type {number}
   * @memberof UserTrainingRecordModel
   */
  statusId?: number = undefined as any;
  /**
   * Training record status description.
   * @type {string}
   * @memberof UserTrainingRecordModel
   */
  status?: string | null = undefined as any;
  /**
   * Training record crated at.
   * @type {string}
   * @memberof UserTrainingRecordModel
   */
  createdAt?: string = undefined as any;

  constructor(data?: Partial<UserTrainingRecordModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 * Current mentor groups statistics.
 * @export
 * @interface ValidateImportingModel
 */
export class ValidateImportingModel {
  /**
   *
   * @type {boolean}
   * @memberof ValidateImportingModel
   */
  isValid?: boolean = undefined as any;
  /**
   *
   * @type {string}
   * @memberof ValidateImportingModel
   */
  message?: string | null = undefined as any;

  constructor(data?: Partial<ValidateImportingModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 * Application form lookups.
 * @export
 * @interface VolunteerEditLookups
 */
export class VolunteerEditLookups {
  /**
   *
   * @type {Array<AcademicYearModel>}
   * @memberof VolunteerEditLookups
   */
  academicYears?: Array<AcademicYearModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof VolunteerEditLookups
   */
  courses?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof VolunteerEditLookups
   */
  yearsOfStudy?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof VolunteerEditLookups
   */
  hoodieSizes?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof VolunteerEditLookups
   */
  trainingSessions?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof VolunteerEditLookups
   */
  tags?: Array<SimpleLookupModel> | null = undefined as any;

  constructor(data?: Partial<VolunteerEditLookups>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface VolunteerModel
 */
export class VolunteerModel {
  /**
   * Volunteer Id
   * @type {number}
   * @memberof VolunteerModel
   */
  id?: number = undefined as any;
  /**
   *
   * @type {string}
   * @memberof VolunteerModel
   */
  createdAt?: string = undefined as any;
  /**
   *
   * @type {string}
   * @memberof VolunteerModel
   */
  updatedAt?: string = undefined as any;
  /**
   * First name.
   * @type {string}
   * @memberof VolunteerModel
   */
  firstName: string = undefined as any;
  /**
   * Last name.
   * @type {string}
   * @memberof VolunteerModel
   */
  lastName: string = undefined as any;
  /**
   *
   * @type {UserModel}
   * @memberof VolunteerModel
   */
  user?: UserModel = undefined as any;
  /**
   *
   * @type {UserModel}
   * @memberof VolunteerModel
   */
  updatedByUser?: UserModel = undefined as any;
  /**
   *
   * @type {SimpleLookupModel}
   * @memberof VolunteerModel
   */
  applicationStatus?: SimpleLookupModel = undefined as any;
  /**
   *
   * @type {SimpleLookupModel}
   * @memberof VolunteerModel
   */
  academicYear?: SimpleLookupModel = undefined as any;
  /**
   *
   * @type {SimpleLookupModel}
   * @memberof VolunteerModel
   */
  trainingSession?: SimpleLookupModel = undefined as any;
  /**
   *
   * @type {Array<VolunteerModel>}
   * @memberof VolunteerModel
   */
  buddies?: Array<VolunteerModel> | null = undefined as any;
  /**
   *
   * @type {SimpleLookupModel}
   * @memberof VolunteerModel
   */
  course?: SimpleLookupModel = undefined as any;
  /**
   *
   * @type {number}
   * @memberof VolunteerModel
   */
  volunteerNumber?: number = undefined as any;
  /**
   *
   * @type {SimpleLookupModel}
   * @memberof VolunteerModel
   */
  yearOfStudy?: SimpleLookupModel = undefined as any;
  /**
   *
   * @type {string}
   * @memberof VolunteerModel
   */
  yearOfStudyOther?: string | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof VolunteerModel
   */
  lastTrainingDate?: string | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof VolunteerModel
   */
  isReturning?: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof VolunteerModel
   */
  isMature?: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof VolunteerModel
   */
  isInternational?: boolean = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof VolunteerModel
   */
  isAccessTapHear?: boolean = undefined as any;
  /**
   *
   * @type {SimpleLookupModel}
   * @memberof VolunteerModel
   */
  hoodieSize?: SimpleLookupModel = undefined as any;
  /**
   *
   * @type {string}
   * @memberof VolunteerModel
   * @deprecated
   */
  tsmCourse1?: string | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof VolunteerModel
   * @deprecated
   */
  tsmCourse2?: string | null = undefined as any;
  /**
   * In one or two sentences, reason why they\'d like to be an S2S mentor.
   * @type {string}
   * @memberof VolunteerModel
   */
  reasonForApplication?: string | null = undefined as any;
  /**
   * What one piece of information do they think is most important for a new student?
   * @type {string}
   * @memberof VolunteerModel
   */
  mostImportantNewStudentInfo?: string | null = undefined as any;
  /**
   * Permission to use the above response anonymously on the website?
   * @type {boolean}
   * @memberof VolunteerModel
   */
  responseInfoWaiver?: boolean = undefined as any;
  /**
   * How did they hear about volunteering with S2S?
   * @type {string}
   * @memberof VolunteerModel
   */
  source?: string | null = undefined as any;
  /**
   * Is there anything they would like to change about the mentoring programme?
   * @type {string}
   * @memberof VolunteerModel
   */
  changesToMentorProgramme?: string | null = undefined as any;
  /**
   * Would they like any additional or refresher training?
   * @type {string}
   * @memberof VolunteerModel
   */
  additionalTrainingRequest?: string | null = undefined as any;
  /**
   *
   * @type {MentorGroupVolunteerModel}
   * @memberof VolunteerModel
   */
  mentorGroup?: MentorGroupVolunteerModel = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof VolunteerModel
   */
  tags?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   * Answer to question: Would you like to repeat the in person training (4 hours)?
   * @type {boolean}
   * @memberof VolunteerModel
   */
  repeatInPersonTraining?: boolean = undefined as any;
  /**
   * Answer to question: Would you like to repeat the Blackboard refresher module?
   * @type {boolean}
   * @memberof VolunteerModel
   */
  repeatBlackboardRefresherModule?: boolean = undefined as any;
  /**
   *
   * @type {InvalidModelStateModel}
   * @memberof VolunteerModel
   */
  modelState?: InvalidModelStateModel = undefined as any;

  constructor(data?: Partial<VolunteerModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface VolunteerModelGridCollection
 */
export class VolunteerModelGridCollection {
  /**
   * Currently fetched page of items.
   * @type {Array<VolunteerModel>}
   * @memberof VolunteerModelGridCollection
   */
  items?: Array<VolunteerModel> | null = undefined as any;
  /**
   * Current page number of the fetched items in the DB.
   * @type {number}
   * @memberof VolunteerModelGridCollection
   */
  currentPage?: number = undefined as any;
  /**
   * Fetched page size.
   * @type {number}
   * @memberof VolunteerModelGridCollection
   */
  pageSize?: number = undefined as any;
  /**
   * Total amount of pages of filtered content.
   * @type {number}
   * @memberof VolunteerModelGridCollection
   */
  pageCount?: number = undefined as any;
  /**
   * Total amount of items in the DB.
   * @type {number}
   * @memberof VolunteerModelGridCollection
   */
  totalItemCount?: number = undefined as any;

  constructor(data?: Partial<VolunteerModelGridCollection>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface VolunteerSearchFilterModel
 */
export class VolunteerSearchFilterModel {
  /**
   *
   * @type {number}
   * @memberof VolunteerSearchFilterModel
   */
  academicYearId?: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof VolunteerSearchFilterModel
   */
  applicationStatusId?: number | null = undefined as any;
  /**
   *
   * @type {boolean}
   * @memberof VolunteerSearchFilterModel
   */
  hasBuddy?: boolean | null = undefined as any;
  /**
   * Is the student a mature student willing to undertake  work specifically with fellow mature students?
   * @type {boolean}
   * @memberof VolunteerSearchFilterModel
   */
  isMature?: boolean | null = undefined as any;
  /**
   * Is the student prepared to mentor international visiting / Erasmus  students instead of JF students as an S2S mentor?
   * @type {boolean}
   * @memberof VolunteerSearchFilterModel
   */
  isInternational?: boolean | null = undefined as any;
  /**
   * Did the student enter Trinity as an  Access/TAP/HEAR student?
   * @type {boolean}
   * @memberof VolunteerSearchFilterModel
   */
  isAccessTapHear?: boolean | null = undefined as any;
  /**
   * Has the student already trained as S2S mentor?
   * @type {boolean}
   * @memberof VolunteerSearchFilterModel
   */
  isReturning?: boolean | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof VolunteerSearchFilterModel
   */
  trainingSessionId?: number | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof VolunteerSearchFilterModel
   */
  missedSessions?: number | null = undefined as any;
  /**
   *
   * @type {Array<number>}
   * @memberof VolunteerSearchFilterModel
   */
  tagIds?: Array<number> | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof VolunteerSearchFilterModel
   */
  courseId?: number | null = undefined as any;
  /**
   * Page to fetch.
   * @type {number}
   * @memberof VolunteerSearchFilterModel
   */
  page?: number | null = undefined as any;
  /**
   * Amount of items to fetch.
   * @type {number}
   * @memberof VolunteerSearchFilterModel
   */
  pageSize?: number | null = undefined as any;
  /**
   * Sort by KEY (entity field name).
   * @type {string}
   * @memberof VolunteerSearchFilterModel
   */
  sortBy?: string | null = undefined as any;
  /**
   * Sort order.  Allowed: \"ascending\" / \"asc\" / \"descending\" / \"desc\".
   * @type {string}
   * @memberof VolunteerSearchFilterModel
   */
  sortOrder?: string | null = undefined as any;
  /**
   * Include soft deleted entities?
   * @type {boolean}
   * @memberof VolunteerSearchFilterModel
   */
  includeDeleted?: boolean | null = undefined as any;
  /**
   * User Id - typically owner id.
   * @type {number}
   * @memberof VolunteerSearchFilterModel
   */
  userId?: number | null = undefined as any;
  /**
   * Search phrase.
   * @type {string}
   * @memberof VolunteerSearchFilterModel
   */
  search?: string | null = undefined as any;

  constructor(data?: Partial<VolunteerSearchFilterModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 * Application form lookups.
 * @export
 * @interface VolunteerSearchLookups
 */
export class VolunteerSearchLookups {
  /**
   *
   * @type {Array<AcademicYearModel>}
   * @memberof VolunteerSearchLookups
   */
  academicYears?: Array<AcademicYearModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof VolunteerSearchLookups
   */
  applicationStatuses?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof VolunteerSearchLookups
   */
  courses?: Array<SimpleLookupModel> | null = undefined as any;
  /**
   *
   * @type {Array<SimpleLookupModel>}
   * @memberof VolunteerSearchLookups
   */
  tags?: Array<SimpleLookupModel> | null = undefined as any;

  constructor(data?: Partial<VolunteerSearchLookups>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface VolunteerTrainingRecordModel
 */
export class VolunteerTrainingRecordModel {
  /**
   *
   * @type {number}
   * @memberof VolunteerTrainingRecordModel
   */
  trainingRecordId?: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof VolunteerTrainingRecordModel
   */
  volunteerId?: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof VolunteerTrainingRecordModel
   */
  userId?: number = undefined as any;
  /**
   * First name.
   * @type {string}
   * @memberof VolunteerTrainingRecordModel
   */
  firstName?: string | null = undefined as any;
  /**
   * Last name.
   * @type {string}
   * @memberof VolunteerTrainingRecordModel
   */
  lastName?: string | null = undefined as any;
  /**
   *
   * @type {string}
   * @memberof VolunteerTrainingRecordModel
   */
  email?: string | null = undefined as any;
  /**
   *
   * @type {number}
   * @memberof VolunteerTrainingRecordModel
   */
  volunteerNumber?: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof VolunteerTrainingRecordModel
   */
  trainingSessionId?: number = undefined as any;
  /**
   *
   * @type {number}
   * @memberof VolunteerTrainingRecordModel
   */
  lastTrainingRecordStatusId?: number = undefined as any;

  constructor(data?: Partial<VolunteerTrainingRecordModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 *
 * @export
 * @interface VolunteerWithdrawModel
 */
export class VolunteerWithdrawModel {
  /**
   * Academic Year Id
   * @type {number}
   * @memberof VolunteerWithdrawModel
   */
  academicYearId: number = undefined as any;
  /**
   * Reason for withdrawing from S2S programme.
   * @type {string}
   * @memberof VolunteerWithdrawModel
   */
  reason: string = undefined as any;
  /**
   *
   * @type {InvalidModelStateModel}
   * @memberof VolunteerWithdrawModel
   */
  modelState?: InvalidModelStateModel = undefined as any;

  constructor(data?: Partial<VolunteerWithdrawModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Allows a user to change his/her own password by providing the old one and selecting a new one.
     * @param {string} oldPassword User\&#39;s old password.
     * @param {string} newPassword User\&#39;s new password.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changePassword: async (oldPassword: string, newPassword: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'oldPassword' is not null or undefined
      assertParamExists("changePassword", "oldPassword", oldPassword);
      // verify required parameter 'newPassword' is not null or undefined
      assertParamExists("changePassword", "newPassword", newPassword);
      const localVarPath = `/api/v1/account/changePassword`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (oldPassword !== undefined) {
        localVarQueryParameter["OldPassword"] = oldPassword;
      }

      if (newPassword !== undefined) {
        localVarQueryParameter["NewPassword"] = newPassword;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Marks user\'s email as confirmed in the database.
     * @param {string} token
     * @param {number} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmEmail: async (token: string, userId?: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      assertParamExists("confirmEmail", "token", token);
      const localVarPath = `/api/v1/account/confirmEmail`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (userId !== undefined) {
        localVarQueryParameter["UserId"] = userId;
      }

      if (token !== undefined) {
        localVarQueryParameter["Token"] = token;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary This initiates the password recovery process (sends an email with password reset instructions).
     * @param {string} email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forgotPassword: async (email: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'email' is not null or undefined
      assertParamExists("forgotPassword", "email", email);
      const localVarPath = `/api/v1/account/forgotPassword`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (email !== undefined) {
        localVarQueryParameter["Email"] = email;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Authenticates the user and returns a JWT token.
     * @param {LoginModel} [loginModel] User login model.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    login: async (loginModel?: LoginModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/account/login`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(loginModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gets a new JWT token for the current context user. Returns  nothing if current JWT token expired or is invalid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshToken: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/account/refreshToken`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Requests the account confirmation email to be re-sent in case  the user lost the original email or it was undelivered.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resendConfirmationEmail: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/account/resendConfirmationEmail`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary This is the follow up endpoint that gets called by the client-side once the user opens the Password Reset  email, clicks on the password recovery link contained in it and submits the new password through a form.
     * @param {string} token
     * @param {string} password
     * @param {number} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetPassword: async (
      token: string,
      password: string,
      userId?: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      assertParamExists("resetPassword", "token", token);
      // verify required parameter 'password' is not null or undefined
      assertParamExists("resetPassword", "password", password);
      const localVarPath = `/api/v1/account/resetPassword`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (userId !== undefined) {
        localVarQueryParameter["UserId"] = userId;
      }

      if (token !== undefined) {
        localVarQueryParameter["Token"] = token;
      }

      if (password !== undefined) {
        localVarQueryParameter["Password"] = password;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Sets a new email for the current user and sends a confirmation email.
     * @param {string} email
     * @param {number} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setEmail: async (email: string, userId?: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'email' is not null or undefined
      assertParamExists("setEmail", "email", email);
      const localVarPath = `/api/v1/account/setEmail`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (userId !== undefined) {
        localVarQueryParameter["UserId"] = userId;
      }

      if (email !== undefined) {
        localVarQueryParameter["Email"] = email;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    switchUser: async (userId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("switchUser", "userId", userId);
      const localVarPath = `/api/v1/account/switchUser/{userId}`.replace(
        `{${"userId"}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Unlocks an account. Administrators only.
     * @param {number} userId User id to unlock.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unlock: async (userId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("unlock", "userId", userId);
      const localVarPath = `/api/v1/account/unlock/{userId}`.replace(
        `{${"userId"}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Allows a user to change his/her own password by providing the old one and selecting a new one.
     * @param {string} oldPassword User\&#39;s old password.
     * @param {string} newPassword User\&#39;s new password.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async changePassword(
      oldPassword: string,
      newPassword: string,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.changePassword(oldPassword, newPassword, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Marks user\'s email as confirmed in the database.
     * @param {string} token
     * @param {number} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async confirmEmail(
      token: string,
      userId?: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.confirmEmail(token, userId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary This initiates the password recovery process (sends an email with password reset instructions).
     * @param {string} email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async forgotPassword(
      email: string,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.forgotPassword(email, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Authenticates the user and returns a JWT token.
     * @param {LoginModel} [loginModel] User login model.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async login(
      loginModel?: LoginModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.login(loginModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Gets a new JWT token for the current context user. Returns  nothing if current JWT token expired or is invalid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async refreshToken(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.refreshToken(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Requests the account confirmation email to be re-sent in case  the user lost the original email or it was undelivered.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resendConfirmationEmail(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.resendConfirmationEmail(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary This is the follow up endpoint that gets called by the client-side once the user opens the Password Reset  email, clicks on the password recovery link contained in it and submits the new password through a form.
     * @param {string} token
     * @param {string} password
     * @param {number} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resetPassword(
      token: string,
      password: string,
      userId?: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(token, password, userId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Sets a new email for the current user and sends a confirmation email.
     * @param {string} email
     * @param {number} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setEmail(
      email: string,
      userId?: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.setEmail(email, userId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async switchUser(
      userId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.switchUser(userId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Unlocks an account. Administrators only.
     * @param {number} userId User id to unlock.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async unlock(
      userId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.unlock(userId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AccountApiFp(configuration);
  return {
    /**
     *
     * @summary Allows a user to change his/her own password by providing the old one and selecting a new one.
     * @param {string} oldPassword User\&#39;s old password.
     * @param {string} newPassword User\&#39;s new password.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changePassword(oldPassword: string, newPassword: string, options?: any): AxiosPromise<void> {
      return localVarFp.changePassword(oldPassword, newPassword, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Marks user\'s email as confirmed in the database.
     * @param {string} token
     * @param {number} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmEmail(token: string, userId?: number, options?: any): AxiosPromise<void> {
      return localVarFp.confirmEmail(token, userId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary This initiates the password recovery process (sends an email with password reset instructions).
     * @param {string} email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forgotPassword(email: string, options?: any): AxiosPromise<void> {
      return localVarFp.forgotPassword(email, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Authenticates the user and returns a JWT token.
     * @param {LoginModel} [loginModel] User login model.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    login(loginModel?: LoginModel, options?: any): AxiosPromise<TokenResponse> {
      return localVarFp.login(loginModel, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Gets a new JWT token for the current context user. Returns  nothing if current JWT token expired or is invalid.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshToken(options?: any): AxiosPromise<TokenResponse> {
      return localVarFp.refreshToken(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Requests the account confirmation email to be re-sent in case  the user lost the original email or it was undelivered.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resendConfirmationEmail(options?: any): AxiosPromise<void> {
      return localVarFp.resendConfirmationEmail(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary This is the follow up endpoint that gets called by the client-side once the user opens the Password Reset  email, clicks on the password recovery link contained in it and submits the new password through a form.
     * @param {string} token
     * @param {string} password
     * @param {number} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetPassword(token: string, password: string, userId?: number, options?: any): AxiosPromise<TokenResponse> {
      return localVarFp.resetPassword(token, password, userId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Sets a new email for the current user and sends a confirmation email.
     * @param {string} email
     * @param {number} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setEmail(email: string, userId?: number, options?: any): AxiosPromise<void> {
      return localVarFp.setEmail(email, userId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    switchUser(userId: number, options?: any): AxiosPromise<TokenResponse> {
      return localVarFp.switchUser(userId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Unlocks an account. Administrators only.
     * @param {number} userId User id to unlock.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unlock(userId: number, options?: any): AxiosPromise<void> {
      return localVarFp.unlock(userId, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * AccountApi - interface
 * @export
 * @interface AccountApi
 */
export interface AccountApiInterface {
  /**
   *
   * @summary Allows a user to change his/her own password by providing the old one and selecting a new one.
   * @param {string} oldPassword User\&#39;s old password.
   * @param {string} newPassword User\&#39;s new password.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApiInterface
   */
  changePassword(oldPassword: string, newPassword: string, options?: any): AxiosPromise<void>;

  /**
   *
   * @summary Marks user\'s email as confirmed in the database.
   * @param {string} token
   * @param {number} [userId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApiInterface
   */
  confirmEmail(token: string, userId?: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @summary This initiates the password recovery process (sends an email with password reset instructions).
   * @param {string} email
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApiInterface
   */
  forgotPassword(email: string, options?: any): AxiosPromise<void>;

  /**
   *
   * @summary Authenticates the user and returns a JWT token.
   * @param {LoginModel} [loginModel] User login model.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApiInterface
   */
  login(loginModel?: LoginModel, options?: any): AxiosPromise<TokenResponse>;

  /**
   *
   * @summary Gets a new JWT token for the current context user. Returns  nothing if current JWT token expired or is invalid.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApiInterface
   */
  refreshToken(options?: any): AxiosPromise<TokenResponse>;

  /**
   *
   * @summary Requests the account confirmation email to be re-sent in case  the user lost the original email or it was undelivered.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApiInterface
   */
  resendConfirmationEmail(options?: any): AxiosPromise<void>;

  /**
   *
   * @summary This is the follow up endpoint that gets called by the client-side once the user opens the Password Reset  email, clicks on the password recovery link contained in it and submits the new password through a form.
   * @param {string} token
   * @param {string} password
   * @param {number} [userId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApiInterface
   */
  resetPassword(token: string, password: string, userId?: number, options?: any): AxiosPromise<TokenResponse>;

  /**
   *
   * @summary Sets a new email for the current user and sends a confirmation email.
   * @param {string} email
   * @param {number} [userId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApiInterface
   */
  setEmail(email: string, userId?: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @param {number} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApiInterface
   */
  switchUser(userId: number, options?: any): AxiosPromise<TokenResponse>;

  /**
   *
   * @summary Unlocks an account. Administrators only.
   * @param {number} userId User id to unlock.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApiInterface
   */
  unlock(userId: number, options?: any): AxiosPromise<void>;
}

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI implements AccountApiInterface {
  /**
   *
   * @summary Allows a user to change his/her own password by providing the old one and selecting a new one.
   * @param {string} oldPassword User\&#39;s old password.
   * @param {string} newPassword User\&#39;s new password.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public changePassword(oldPassword: string, newPassword: string, options?: any) {
    return AccountApiFp(this.configuration)
      .changePassword(oldPassword, newPassword, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Marks user\'s email as confirmed in the database.
   * @param {string} token
   * @param {number} [userId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public confirmEmail(token: string, userId?: number, options?: any) {
    return AccountApiFp(this.configuration)
      .confirmEmail(token, userId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary This initiates the password recovery process (sends an email with password reset instructions).
   * @param {string} email
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public forgotPassword(email: string, options?: any) {
    return AccountApiFp(this.configuration)
      .forgotPassword(email, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Authenticates the user and returns a JWT token.
   * @param {LoginModel} [loginModel] User login model.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public login(loginModel?: LoginModel, options?: any) {
    return AccountApiFp(this.configuration)
      .login(loginModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Gets a new JWT token for the current context user. Returns  nothing if current JWT token expired or is invalid.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public refreshToken(options?: any) {
    return AccountApiFp(this.configuration)
      .refreshToken(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Requests the account confirmation email to be re-sent in case  the user lost the original email or it was undelivered.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public resendConfirmationEmail(options?: any) {
    return AccountApiFp(this.configuration)
      .resendConfirmationEmail(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary This is the follow up endpoint that gets called by the client-side once the user opens the Password Reset  email, clicks on the password recovery link contained in it and submits the new password through a form.
   * @param {string} token
   * @param {string} password
   * @param {number} [userId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public resetPassword(token: string, password: string, userId?: number, options?: any) {
    return AccountApiFp(this.configuration)
      .resetPassword(token, password, userId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Sets a new email for the current user and sends a confirmation email.
   * @param {string} email
   * @param {number} [userId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public setEmail(email: string, userId?: number, options?: any) {
    return AccountApiFp(this.configuration)
      .setEmail(email, userId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public switchUser(userId: number, options?: any) {
    return AccountApiFp(this.configuration)
      .switchUser(userId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Unlocks an account. Administrators only.
   * @param {number} userId User id to unlock.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AccountApi
   */
  public unlock(userId: number, options?: any) {
    return AccountApiFp(this.configuration)
      .unlock(userId, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * AnonymisationApi - axios parameter creator
 * @export
 */
export const AnonymisationApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    run: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/anonymisation/run`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AnonymisationApi - functional programming interface
 * @export
 */
export const AnonymisationApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = AnonymisationApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async run(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.run(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AnonymisationApi - factory interface
 * @export
 */
export const AnonymisationApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AnonymisationApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    run(options?: any): AxiosPromise<void> {
      return localVarFp.run(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * AnonymisationApi - interface
 * @export
 * @interface AnonymisationApi
 */
export interface AnonymisationApiInterface {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnonymisationApiInterface
   */
  run(options?: any): AxiosPromise<void>;
}

/**
 * AnonymisationApi - object-oriented interface
 * @export
 * @class AnonymisationApi
 * @extends {BaseAPI}
 */
export class AnonymisationApi extends BaseAPI implements AnonymisationApiInterface {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AnonymisationApi
   */
  public run(options?: any) {
    return AnonymisationApiFp(this.configuration)
      .run(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ApplicationApi - axios parameter creator
 * @export
 */
export const ApplicationApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {VolunteerSearchFilterModel} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterVolunteers: async (filter?: VolunteerSearchFilterModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/application/volunteer/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filter !== undefined) {
        localVarQueryParameter["filter"] = filter;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAcademicYearAvailableForApplication: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/application/available/academic/year`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get application form lookups.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApplicationLookups: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/application/lookups`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApplicationStatus: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/application/statuses`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} volunteerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVolunteerById: async (volunteerId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'volunteerId' is not null or undefined
      assertParamExists("getVolunteerById", "volunteerId", volunteerId);
      const localVarPath = `/api/v1/application/volunteer/{volunteerId}`.replace(
        `{${"volunteerId"}}`,
        encodeURIComponent(String(volunteerId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} volunteerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVolunteerEditLookups: async (volunteerId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'volunteerId' is not null or undefined
      assertParamExists("getVolunteerEditLookups", "volunteerId", volunteerId);
      const localVarPath = `/api/v1/application/volunteer/edit/lookups/{volunteerId}`.replace(
        `{${"volunteerId"}}`,
        encodeURIComponent(String(volunteerId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVolunteerSearchLookups: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/application/volunteer/search/lookups`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {VolunteerSearchFilterModel} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVolunteersCsv: async (filter?: VolunteerSearchFilterModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/application/volunteer/csv`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filter !== undefined) {
        localVarQueryParameter["filter"] = filter;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Creates a volunteer application.
     * @param {ApplicationModel} [applicationModel] Volunteer application model.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postApplication: async (applicationModel?: ApplicationModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/application`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(applicationModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} volunteerId
     * @param {number} applicationStatusId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStatus: async (volunteerId: number, applicationStatusId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'volunteerId' is not null or undefined
      assertParamExists("updateStatus", "volunteerId", volunteerId);
      // verify required parameter 'applicationStatusId' is not null or undefined
      assertParamExists("updateStatus", "applicationStatusId", applicationStatusId);
      const localVarPath = `/api/v1/application/volunteer/{volunteerId}/status/{applicationStatusId}`
        .replace(`{${"volunteerId"}}`, encodeURIComponent(String(volunteerId)))
        .replace(`{${"applicationStatusId"}}`, encodeURIComponent(String(applicationStatusId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {VolunteerModel} [volunteerModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateVolunteer: async (volunteerModel?: VolunteerModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/application/volunteer/edit`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(volunteerModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ApplicationApi - functional programming interface
 * @export
 */
export const ApplicationApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = ApplicationApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {VolunteerSearchFilterModel} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filterVolunteers(
      filter?: VolunteerSearchFilterModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VolunteerModelGridCollection>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filterVolunteers(filter, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAcademicYearAvailableForApplication(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AcademicYearModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAcademicYearAvailableForApplication(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get application form lookups.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getApplicationLookups(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationLookups>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getApplicationLookups(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getApplicationStatus(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SimpleLookupModel>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getApplicationStatus(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} volunteerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVolunteerById(
      volunteerId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VolunteerModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getVolunteerById(volunteerId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} volunteerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVolunteerEditLookups(
      volunteerId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VolunteerEditLookups>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getVolunteerEditLookups(volunteerId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVolunteerSearchLookups(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VolunteerSearchLookups>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getVolunteerSearchLookups(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {VolunteerSearchFilterModel} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVolunteersCsv(
      filter?: VolunteerSearchFilterModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getVolunteersCsv(filter, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Creates a volunteer application.
     * @param {ApplicationModel} [applicationModel] Volunteer application model.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postApplication(
      applicationModel?: ApplicationModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postApplication(applicationModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} volunteerId
     * @param {number} applicationStatusId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateStatus(
      volunteerId: number,
      applicationStatusId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateStatus(volunteerId, applicationStatusId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {VolunteerModel} [volunteerModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateVolunteer(
      volunteerModel?: VolunteerModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateVolunteer(volunteerModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ApplicationApi - factory interface
 * @export
 */
export const ApplicationApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ApplicationApiFp(configuration);
  return {
    /**
     *
     * @param {VolunteerSearchFilterModel} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterVolunteers(filter?: VolunteerSearchFilterModel, options?: any): AxiosPromise<VolunteerModelGridCollection> {
      return localVarFp.filterVolunteers(filter, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAcademicYearAvailableForApplication(options?: any): AxiosPromise<AcademicYearModel> {
      return localVarFp.getAcademicYearAvailableForApplication(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get application form lookups.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApplicationLookups(options?: any): AxiosPromise<ApplicationLookups> {
      return localVarFp.getApplicationLookups(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getApplicationStatus(options?: any): AxiosPromise<Array<SimpleLookupModel>> {
      return localVarFp.getApplicationStatus(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} volunteerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVolunteerById(volunteerId: number, options?: any): AxiosPromise<VolunteerModel> {
      return localVarFp.getVolunteerById(volunteerId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} volunteerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVolunteerEditLookups(volunteerId: number, options?: any): AxiosPromise<VolunteerEditLookups> {
      return localVarFp.getVolunteerEditLookups(volunteerId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVolunteerSearchLookups(options?: any): AxiosPromise<VolunteerSearchLookups> {
      return localVarFp.getVolunteerSearchLookups(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {VolunteerSearchFilterModel} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVolunteersCsv(filter?: VolunteerSearchFilterModel, options?: any): AxiosPromise<string> {
      return localVarFp.getVolunteersCsv(filter, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Creates a volunteer application.
     * @param {ApplicationModel} [applicationModel] Volunteer application model.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postApplication(applicationModel?: ApplicationModel, options?: any): AxiosPromise<number> {
      return localVarFp.postApplication(applicationModel, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} volunteerId
     * @param {number} applicationStatusId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStatus(volunteerId: number, applicationStatusId: number, options?: any): AxiosPromise<boolean> {
      return localVarFp
        .updateStatus(volunteerId, applicationStatusId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {VolunteerModel} [volunteerModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateVolunteer(volunteerModel?: VolunteerModel, options?: any): AxiosPromise<boolean> {
      return localVarFp.updateVolunteer(volunteerModel, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * ApplicationApi - interface
 * @export
 * @interface ApplicationApi
 */
export interface ApplicationApiInterface {
  /**
   *
   * @param {VolunteerSearchFilterModel} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApiInterface
   */
  filterVolunteers(filter?: VolunteerSearchFilterModel, options?: any): AxiosPromise<VolunteerModelGridCollection>;

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApiInterface
   */
  getAcademicYearAvailableForApplication(options?: any): AxiosPromise<AcademicYearModel>;

  /**
   *
   * @summary Get application form lookups.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApiInterface
   */
  getApplicationLookups(options?: any): AxiosPromise<ApplicationLookups>;

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApiInterface
   */
  getApplicationStatus(options?: any): AxiosPromise<Array<SimpleLookupModel>>;

  /**
   *
   * @param {number} volunteerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApiInterface
   */
  getVolunteerById(volunteerId: number, options?: any): AxiosPromise<VolunteerModel>;

  /**
   *
   * @param {number} volunteerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApiInterface
   */
  getVolunteerEditLookups(volunteerId: number, options?: any): AxiosPromise<VolunteerEditLookups>;

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApiInterface
   */
  getVolunteerSearchLookups(options?: any): AxiosPromise<VolunteerSearchLookups>;

  /**
   *
   * @param {VolunteerSearchFilterModel} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApiInterface
   */
  getVolunteersCsv(filter?: VolunteerSearchFilterModel, options?: any): AxiosPromise<string>;

  /**
   *
   * @summary Creates a volunteer application.
   * @param {ApplicationModel} [applicationModel] Volunteer application model.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApiInterface
   */
  postApplication(applicationModel?: ApplicationModel, options?: any): AxiosPromise<number>;

  /**
   *
   * @param {number} volunteerId
   * @param {number} applicationStatusId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApiInterface
   */
  updateStatus(volunteerId: number, applicationStatusId: number, options?: any): AxiosPromise<boolean>;

  /**
   *
   * @param {VolunteerModel} [volunteerModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApiInterface
   */
  updateVolunteer(volunteerModel?: VolunteerModel, options?: any): AxiosPromise<boolean>;
}

/**
 * ApplicationApi - object-oriented interface
 * @export
 * @class ApplicationApi
 * @extends {BaseAPI}
 */
export class ApplicationApi extends BaseAPI implements ApplicationApiInterface {
  /**
   *
   * @param {VolunteerSearchFilterModel} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public filterVolunteers(filter?: VolunteerSearchFilterModel, options?: any) {
    return ApplicationApiFp(this.configuration)
      .filterVolunteers(filter, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public getAcademicYearAvailableForApplication(options?: any) {
    return ApplicationApiFp(this.configuration)
      .getAcademicYearAvailableForApplication(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get application form lookups.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public getApplicationLookups(options?: any) {
    return ApplicationApiFp(this.configuration)
      .getApplicationLookups(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public getApplicationStatus(options?: any) {
    return ApplicationApiFp(this.configuration)
      .getApplicationStatus(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} volunteerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public getVolunteerById(volunteerId: number, options?: any) {
    return ApplicationApiFp(this.configuration)
      .getVolunteerById(volunteerId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} volunteerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public getVolunteerEditLookups(volunteerId: number, options?: any) {
    return ApplicationApiFp(this.configuration)
      .getVolunteerEditLookups(volunteerId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public getVolunteerSearchLookups(options?: any) {
    return ApplicationApiFp(this.configuration)
      .getVolunteerSearchLookups(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {VolunteerSearchFilterModel} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public getVolunteersCsv(filter?: VolunteerSearchFilterModel, options?: any) {
    return ApplicationApiFp(this.configuration)
      .getVolunteersCsv(filter, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Creates a volunteer application.
   * @param {ApplicationModel} [applicationModel] Volunteer application model.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public postApplication(applicationModel?: ApplicationModel, options?: any) {
    return ApplicationApiFp(this.configuration)
      .postApplication(applicationModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} volunteerId
   * @param {number} applicationStatusId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public updateStatus(volunteerId: number, applicationStatusId: number, options?: any) {
    return ApplicationApiFp(this.configuration)
      .updateStatus(volunteerId, applicationStatusId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {VolunteerModel} [volunteerModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ApplicationApi
   */
  public updateVolunteer(volunteerModel?: VolunteerModel, options?: any) {
    return ApplicationApiFp(this.configuration)
      .updateVolunteer(volunteerModel, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * AuditRecordApi - axios parameter creator
 * @export
 */
export const AuditRecordApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Delete Log Activity
     * @param {number} auditRecordId Audit Record Id to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAcvity: async (auditRecordId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'auditRecordId' is not null or undefined
      assertParamExists("deleteAcvity", "auditRecordId", auditRecordId);
      const localVarPath = `/api/v1/audit/{auditRecordId}`.replace(
        `{${"auditRecordId"}}`,
        encodeURIComponent(String(auditRecordId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AuditRecordFilterModel} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterAuditRecord: async (filter?: AuditRecordFilterModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/audit/filter`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filter !== undefined) {
        localVarQueryParameter["filter"] = filter;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAuditRecordType: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/audit/types`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AuditRecordFilterModel} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAuditRecordsCsv: async (filter?: AuditRecordFilterModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/audit/audit/csv`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filter !== undefined) {
        localVarQueryParameter["filter"] = filter;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} academicYearId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLastAcvityHours: async (academicYearId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'academicYearId' is not null or undefined
      assertParamExists("getLastAcvityHours", "academicYearId", academicYearId);
      const localVarPath = `/api/v1/audit/last/hours/{academicYearId}`.replace(
        `{${"academicYearId"}}`,
        encodeURIComponent(String(academicYearId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {AuditLogEventModel} [auditLogEventModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveAuditRecord: async (auditLogEventModel?: AuditLogEventModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/audit/save`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(auditLogEventModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AuditRecordApi - functional programming interface
 * @export
 */
export const AuditRecordApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = AuditRecordApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete Log Activity
     * @param {number} auditRecordId Audit Record Id to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteAcvity(
      auditRecordId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAcvity(auditRecordId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {AuditRecordFilterModel} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filterAuditRecord(
      filter?: AuditRecordFilterModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuditLogEventModelGridCollection>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filterAuditRecord(filter, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAuditRecordType(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SimpleLookupModel>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAuditRecordType(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {AuditRecordFilterModel} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAuditRecordsCsv(
      filter?: AuditRecordFilterModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAuditRecordsCsv(filter, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} academicYearId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLastAcvityHours(
      academicYearId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LastActivityHoursModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLastAcvityHours(academicYearId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {AuditLogEventModel} [auditLogEventModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveAuditRecord(
      auditLogEventModel?: AuditLogEventModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveAuditRecord(auditLogEventModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AuditRecordApi - factory interface
 * @export
 */
export const AuditRecordApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = AuditRecordApiFp(configuration);
  return {
    /**
     *
     * @summary Delete Log Activity
     * @param {number} auditRecordId Audit Record Id to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAcvity(auditRecordId: number, options?: any): AxiosPromise<boolean> {
      return localVarFp.deleteAcvity(auditRecordId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {AuditRecordFilterModel} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterAuditRecord(filter?: AuditRecordFilterModel, options?: any): AxiosPromise<AuditLogEventModelGridCollection> {
      return localVarFp.filterAuditRecord(filter, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAuditRecordType(options?: any): AxiosPromise<Array<SimpleLookupModel>> {
      return localVarFp.getAuditRecordType(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {AuditRecordFilterModel} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAuditRecordsCsv(filter?: AuditRecordFilterModel, options?: any): AxiosPromise<string> {
      return localVarFp.getAuditRecordsCsv(filter, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} academicYearId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLastAcvityHours(academicYearId: number, options?: any): AxiosPromise<LastActivityHoursModel> {
      return localVarFp.getLastAcvityHours(academicYearId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {AuditLogEventModel} [auditLogEventModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveAuditRecord(auditLogEventModel?: AuditLogEventModel, options?: any): AxiosPromise<boolean> {
      return localVarFp.saveAuditRecord(auditLogEventModel, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * AuditRecordApi - interface
 * @export
 * @interface AuditRecordApi
 */
export interface AuditRecordApiInterface {
  /**
   *
   * @summary Delete Log Activity
   * @param {number} auditRecordId Audit Record Id to be deleted
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuditRecordApiInterface
   */
  deleteAcvity(auditRecordId: number, options?: any): AxiosPromise<boolean>;

  /**
   *
   * @param {AuditRecordFilterModel} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuditRecordApiInterface
   */
  filterAuditRecord(filter?: AuditRecordFilterModel, options?: any): AxiosPromise<AuditLogEventModelGridCollection>;

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuditRecordApiInterface
   */
  getAuditRecordType(options?: any): AxiosPromise<Array<SimpleLookupModel>>;

  /**
   *
   * @param {AuditRecordFilterModel} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuditRecordApiInterface
   */
  getAuditRecordsCsv(filter?: AuditRecordFilterModel, options?: any): AxiosPromise<string>;

  /**
   *
   * @param {number} academicYearId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuditRecordApiInterface
   */
  getLastAcvityHours(academicYearId: number, options?: any): AxiosPromise<LastActivityHoursModel>;

  /**
   *
   * @param {AuditLogEventModel} [auditLogEventModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuditRecordApiInterface
   */
  saveAuditRecord(auditLogEventModel?: AuditLogEventModel, options?: any): AxiosPromise<boolean>;
}

/**
 * AuditRecordApi - object-oriented interface
 * @export
 * @class AuditRecordApi
 * @extends {BaseAPI}
 */
export class AuditRecordApi extends BaseAPI implements AuditRecordApiInterface {
  /**
   *
   * @summary Delete Log Activity
   * @param {number} auditRecordId Audit Record Id to be deleted
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuditRecordApi
   */
  public deleteAcvity(auditRecordId: number, options?: any) {
    return AuditRecordApiFp(this.configuration)
      .deleteAcvity(auditRecordId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AuditRecordFilterModel} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuditRecordApi
   */
  public filterAuditRecord(filter?: AuditRecordFilterModel, options?: any) {
    return AuditRecordApiFp(this.configuration)
      .filterAuditRecord(filter, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuditRecordApi
   */
  public getAuditRecordType(options?: any) {
    return AuditRecordApiFp(this.configuration)
      .getAuditRecordType(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AuditRecordFilterModel} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuditRecordApi
   */
  public getAuditRecordsCsv(filter?: AuditRecordFilterModel, options?: any) {
    return AuditRecordApiFp(this.configuration)
      .getAuditRecordsCsv(filter, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} academicYearId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuditRecordApi
   */
  public getLastAcvityHours(academicYearId: number, options?: any) {
    return AuditRecordApiFp(this.configuration)
      .getLastAcvityHours(academicYearId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {AuditLogEventModel} [auditLogEventModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuditRecordApi
   */
  public saveAuditRecord(auditLogEventModel?: AuditLogEventModel, options?: any) {
    return AuditRecordApiFp(this.configuration)
      .saveAuditRecord(auditLogEventModel, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * AzurePermissionsApi - axios parameter creator
 * @export
 */
export const AzurePermissionsApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {AzureFirewallAddRule} [azureFirewallAddRule]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addFirewallRule: async (azureFirewallAddRule?: AzureFirewallAddRule, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/azure/addRule`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(azureFirewallAddRule, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrentFirewallRules: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/azure/getAll`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AzurePermissionsApi - functional programming interface
 * @export
 */
export const AzurePermissionsApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = AzurePermissionsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {AzureFirewallAddRule} [azureFirewallAddRule]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addFirewallRule(
      azureFirewallAddRule?: AzureFirewallAddRule,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addFirewallRule(azureFirewallAddRule, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCurrentFirewallRules(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AzureFirewallRuleVm>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentFirewallRules(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AzurePermissionsApi - factory interface
 * @export
 */
export const AzurePermissionsApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AzurePermissionsApiFp(configuration);
  return {
    /**
     *
     * @param {AzureFirewallAddRule} [azureFirewallAddRule]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addFirewallRule(azureFirewallAddRule?: AzureFirewallAddRule, options?: any): AxiosPromise<void> {
      return localVarFp.addFirewallRule(azureFirewallAddRule, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrentFirewallRules(options?: any): AxiosPromise<AzureFirewallRuleVm> {
      return localVarFp.getCurrentFirewallRules(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * AzurePermissionsApi - interface
 * @export
 * @interface AzurePermissionsApi
 */
export interface AzurePermissionsApiInterface {
  /**
   *
   * @param {AzureFirewallAddRule} [azureFirewallAddRule]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AzurePermissionsApiInterface
   */
  addFirewallRule(azureFirewallAddRule?: AzureFirewallAddRule, options?: any): AxiosPromise<void>;

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AzurePermissionsApiInterface
   */
  getCurrentFirewallRules(options?: any): AxiosPromise<AzureFirewallRuleVm>;
}

/**
 * AzurePermissionsApi - object-oriented interface
 * @export
 * @class AzurePermissionsApi
 * @extends {BaseAPI}
 */
export class AzurePermissionsApi extends BaseAPI implements AzurePermissionsApiInterface {
  /**
   *
   * @param {AzureFirewallAddRule} [azureFirewallAddRule]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AzurePermissionsApi
   */
  public addFirewallRule(azureFirewallAddRule?: AzureFirewallAddRule, options?: any) {
    return AzurePermissionsApiFp(this.configuration)
      .addFirewallRule(azureFirewallAddRule, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AzurePermissionsApi
   */
  public getCurrentFirewallRules(options?: any) {
    return AzurePermissionsApiFp(this.configuration)
      .getCurrentFirewallRules(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * EmailApi - axios parameter creator
 * @export
 */
export const EmailApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEmailToken: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/email/token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Unlocks an account. Administrators only.
     * @param {EmailMessageModel} [emailMessageModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendEmail: async (emailMessageModel?: EmailMessageModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/email/send`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(emailMessageModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * EmailApi - functional programming interface
 * @export
 */
export const EmailApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = EmailApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEmailToken(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEmailToken(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Unlocks an account. Administrators only.
     * @param {EmailMessageModel} [emailMessageModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sendEmail(
      emailMessageModel?: EmailMessageModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sendEmail(emailMessageModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * EmailApi - factory interface
 * @export
 */
export const EmailApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = EmailApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEmailToken(options?: any): AxiosPromise<void> {
      return localVarFp.getEmailToken(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Unlocks an account. Administrators only.
     * @param {EmailMessageModel} [emailMessageModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendEmail(emailMessageModel?: EmailMessageModel, options?: any): AxiosPromise<void> {
      return localVarFp.sendEmail(emailMessageModel, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * EmailApi - interface
 * @export
 * @interface EmailApi
 */
export interface EmailApiInterface {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmailApiInterface
   */
  getEmailToken(options?: any): AxiosPromise<void>;

  /**
   *
   * @summary Unlocks an account. Administrators only.
   * @param {EmailMessageModel} [emailMessageModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmailApiInterface
   */
  sendEmail(emailMessageModel?: EmailMessageModel, options?: any): AxiosPromise<void>;
}

/**
 * EmailApi - object-oriented interface
 * @export
 * @class EmailApi
 * @extends {BaseAPI}
 */
export class EmailApi extends BaseAPI implements EmailApiInterface {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmailApi
   */
  public getEmailToken(options?: any) {
    return EmailApiFp(this.configuration)
      .getEmailToken(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Unlocks an account. Administrators only.
   * @param {EmailMessageModel} [emailMessageModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EmailApi
   */
  public sendEmail(emailMessageModel?: EmailMessageModel, options?: any) {
    return EmailApiFp(this.configuration)
      .sendEmail(emailMessageModel, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * HealthCheckApi - axios parameter creator
 * @export
 */
export const HealthCheckApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Checks health of the API.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthCheck: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/healthCheck`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * HealthCheckApi - functional programming interface
 * @export
 */
export const HealthCheckApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = HealthCheckApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Checks health of the API.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async healthCheck(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.healthCheck(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * HealthCheckApi - factory interface
 * @export
 */
export const HealthCheckApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = HealthCheckApiFp(configuration);
  return {
    /**
     *
     * @summary Checks health of the API.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    healthCheck(options?: any): AxiosPromise<void> {
      return localVarFp.healthCheck(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * HealthCheckApi - interface
 * @export
 * @interface HealthCheckApi
 */
export interface HealthCheckApiInterface {
  /**
   *
   * @summary Checks health of the API.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HealthCheckApiInterface
   */
  healthCheck(options?: any): AxiosPromise<void>;
}

/**
 * HealthCheckApi - object-oriented interface
 * @export
 * @class HealthCheckApi
 * @extends {BaseAPI}
 */
export class HealthCheckApi extends BaseAPI implements HealthCheckApiInterface {
  /**
   *
   * @summary Checks health of the API.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HealthCheckApi
   */
  public healthCheck(options?: any) {
    return HealthCheckApiFp(this.configuration)
      .healthCheck(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * MentoringApi - axios parameter creator
 * @export
 */
export const MentoringApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} mentorGroupId
     * @param {number} volunteerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addMentorToGroup: async (mentorGroupId: number, volunteerId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'mentorGroupId' is not null or undefined
      assertParamExists("addMentorToGroup", "mentorGroupId", mentorGroupId);
      // verify required parameter 'volunteerId' is not null or undefined
      assertParamExists("addMentorToGroup", "volunteerId", volunteerId);
      const localVarPath = `/api/v1/mentoring/groups/{mentorGroupId}/addMentor/{volunteerId}`
        .replace(`{${"mentorGroupId"}}`, encodeURIComponent(String(mentorGroupId)))
        .replace(`{${"volunteerId"}}`, encodeURIComponent(String(volunteerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreateMentorGroupModel} [createMentorGroupModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMentorGroup: async (
      createMentorGroupModel?: CreateMentorGroupModel,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/mentoring/groups`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createMentorGroupModel,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} mentorGroupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMentorGroup: async (mentorGroupId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'mentorGroupId' is not null or undefined
      assertParamExists("deleteMentorGroup", "mentorGroupId", mentorGroupId);
      const localVarPath = `/api/v1/mentoring/groups/{mentorGroupId}`.replace(
        `{${"mentorGroupId"}}`,
        encodeURIComponent(String(mentorGroupId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {MenteeFilterModel} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterMentees: async (filter?: MenteeFilterModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/mentoring/mentee/filter`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filter !== undefined) {
        localVarQueryParameter["filter"] = filter;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Filter mentor groups.
     * @param {MentorGroupsFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterMentorGroups: async (filter?: MentorGroupsFilter, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/mentoring/groups/filter`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filter !== undefined) {
        localVarQueryParameter["filter"] = filter;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {MentorsFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterMentors: async (filter?: MentorsFilter, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/mentoring/mentors/filter`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filter !== undefined) {
        localVarQueryParameter["filter"] = filter;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get mentee filtering lookups.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMenteeFilteringLookups: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/mentoring/mentee/lookups`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get mentoring groups filtering lookups.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMentorGroupsFilteringLookups: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/mentoring/groups/lookups`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {MentorGroupsStatsFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMentorGroupsStats: async (filter?: MentorGroupsStatsFilter, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/mentoring/groups/stats`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filter !== undefined) {
        localVarQueryParameter["filter"] = filter;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} academicYearId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMyBuddies: async (academicYearId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'academicYearId' is not null or undefined
      assertParamExists("getMyBuddies", "academicYearId", academicYearId);
      const localVarPath = `/api/v1/mentoring/buddies/{academicYearId}`.replace(
        `{${"academicYearId"}}`,
        encodeURIComponent(String(academicYearId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} academicYearId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMyMentorGroup: async (academicYearId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'academicYearId' is not null or undefined
      assertParamExists("getMyMentorGroup", "academicYearId", academicYearId);
      const localVarPath = `/api/v1/mentoring/myMentorGroup/{academicYearId}`.replace(
        `{${"academicYearId"}}`,
        encodeURIComponent(String(academicYearId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} academicYearId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPrintoutBatchGroups: async (academicYearId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'academicYearId' is not null or undefined
      assertParamExists("getPrintoutBatchGroups", "academicYearId", academicYearId);
      const localVarPath = `/api/v1/mentoring/groups/printout/{academicYearId}`.replace(
        `{${"academicYearId"}}`,
        encodeURIComponent(String(academicYearId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} academicYearId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importMentees: async (academicYearId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'academicYearId' is not null or undefined
      assertParamExists("importMentees", "academicYearId", academicYearId);
      const localVarPath = `/api/v1/mentoring/mentees/import/{academicYearId}`.replace(
        `{${"academicYearId"}}`,
        encodeURIComponent(String(academicYearId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {MyBuddiesPostModel} [myBuddiesPostModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postMyBuddies: async (myBuddiesPostModel?: MyBuddiesPostModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/mentoring/buddies`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(myBuddiesPostModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} menteeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeMentee: async (menteeId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'menteeId' is not null or undefined
      assertParamExists("removeMentee", "menteeId", menteeId);
      const localVarPath = `/api/v1/mentoring/removeMentee/{menteeId}`.replace(
        `{${"menteeId"}}`,
        encodeURIComponent(String(menteeId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} mentorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeMentorFromGroup: async (mentorId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'mentorId' is not null or undefined
      assertParamExists("removeMentorFromGroup", "mentorId", mentorId);
      const localVarPath = `/api/v1/mentoring/removeMentor/{mentorId}`.replace(
        `{${"mentorId"}}`,
        encodeURIComponent(String(mentorId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UpdateMentorGroupModel} [updateMentorGroupModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMentorGroup: async (
      updateMentorGroupModel?: UpdateMentorGroupModel,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/mentoring/groups`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateMentorGroupModel,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} academicYearId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateMenteeImporting: async (academicYearId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'academicYearId' is not null or undefined
      assertParamExists("validateMenteeImporting", "academicYearId", academicYearId);
      const localVarPath = `/api/v1/mentoring/mentees/import/validate/{academicYearId}`.replace(
        `{${"academicYearId"}}`,
        encodeURIComponent(String(academicYearId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MentoringApi - functional programming interface
 * @export
 */
export const MentoringApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = MentoringApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} mentorGroupId
     * @param {number} volunteerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addMentorToGroup(
      mentorGroupId: number,
      volunteerId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addMentorToGroup(mentorGroupId, volunteerId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CreateMentorGroupModel} [createMentorGroupModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createMentorGroup(
      createMentorGroupModel?: CreateMentorGroupModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createMentorGroup(createMentorGroupModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} mentorGroupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteMentorGroup(
      mentorGroupId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMentorGroup(mentorGroupId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {MenteeFilterModel} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filterMentees(
      filter?: MenteeFilterModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MenteeModelGridCollection>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filterMentees(filter, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Filter mentor groups.
     * @param {MentorGroupsFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filterMentorGroups(
      filter?: MentorGroupsFilter,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MentorGroupModel>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filterMentorGroups(filter, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {MentorsFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filterMentors(
      filter?: MentorsFilter,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MentorCollectionModel>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filterMentors(filter, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get mentee filtering lookups.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMenteeFilteringLookups(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MenteeLookups>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMenteeFilteringLookups(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get mentoring groups filtering lookups.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMentorGroupsFilteringLookups(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MentorGroupsLookups>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMentorGroupsFilteringLookups(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {MentorGroupsStatsFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMentorGroupsStats(
      filter?: MentorGroupsStatsFilter,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MentorGroupsStatsModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMentorGroupsStats(filter, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} academicYearId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMyBuddies(
      academicYearId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyBuddiesFormModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMyBuddies(academicYearId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} academicYearId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMyMentorGroup(
      academicYearId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyMentorGroupModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMyMentorGroup(academicYearId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} academicYearId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPrintoutBatchGroups(
      academicYearId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PrintoutBatchGroupModel>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPrintoutBatchGroups(academicYearId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} academicYearId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async importMentees(
      academicYearId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.importMentees(academicYearId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {MyBuddiesPostModel} [myBuddiesPostModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postMyBuddies(
      myBuddiesPostModel?: MyBuddiesPostModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postMyBuddies(myBuddiesPostModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} menteeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeMentee(
      menteeId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.removeMentee(menteeId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} mentorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeMentorFromGroup(
      mentorId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.removeMentorFromGroup(mentorId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {UpdateMentorGroupModel} [updateMentorGroupModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateMentorGroup(
      updateMentorGroupModel?: UpdateMentorGroupModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateMentorGroup(updateMentorGroupModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} academicYearId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async validateMenteeImporting(
      academicYearId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateImportingModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.validateMenteeImporting(academicYearId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * MentoringApi - factory interface
 * @export
 */
export const MentoringApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = MentoringApiFp(configuration);
  return {
    /**
     *
     * @param {number} mentorGroupId
     * @param {number} volunteerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addMentorToGroup(mentorGroupId: number, volunteerId: number, options?: any): AxiosPromise<void> {
      return localVarFp.addMentorToGroup(mentorGroupId, volunteerId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {CreateMentorGroupModel} [createMentorGroupModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMentorGroup(createMentorGroupModel?: CreateMentorGroupModel, options?: any): AxiosPromise<number> {
      return localVarFp.createMentorGroup(createMentorGroupModel, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} mentorGroupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMentorGroup(mentorGroupId: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteMentorGroup(mentorGroupId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {MenteeFilterModel} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterMentees(filter?: MenteeFilterModel, options?: any): AxiosPromise<MenteeModelGridCollection> {
      return localVarFp.filterMentees(filter, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Filter mentor groups.
     * @param {MentorGroupsFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterMentorGroups(filter?: MentorGroupsFilter, options?: any): AxiosPromise<Array<MentorGroupModel>> {
      return localVarFp.filterMentorGroups(filter, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {MentorsFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterMentors(filter?: MentorsFilter, options?: any): AxiosPromise<Array<MentorCollectionModel>> {
      return localVarFp.filterMentors(filter, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get mentee filtering lookups.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMenteeFilteringLookups(options?: any): AxiosPromise<MenteeLookups> {
      return localVarFp.getMenteeFilteringLookups(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get mentoring groups filtering lookups.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMentorGroupsFilteringLookups(options?: any): AxiosPromise<MentorGroupsLookups> {
      return localVarFp.getMentorGroupsFilteringLookups(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {MentorGroupsStatsFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMentorGroupsStats(filter?: MentorGroupsStatsFilter, options?: any): AxiosPromise<MentorGroupsStatsModel> {
      return localVarFp.getMentorGroupsStats(filter, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} academicYearId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMyBuddies(academicYearId: number, options?: any): AxiosPromise<MyBuddiesFormModel> {
      return localVarFp.getMyBuddies(academicYearId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} academicYearId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMyMentorGroup(academicYearId: number, options?: any): AxiosPromise<MyMentorGroupModel> {
      return localVarFp.getMyMentorGroup(academicYearId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} academicYearId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPrintoutBatchGroups(academicYearId: number, options?: any): AxiosPromise<Array<PrintoutBatchGroupModel>> {
      return localVarFp.getPrintoutBatchGroups(academicYearId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} academicYearId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importMentees(academicYearId: number, options?: any): AxiosPromise<boolean> {
      return localVarFp.importMentees(academicYearId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {MyBuddiesPostModel} [myBuddiesPostModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postMyBuddies(myBuddiesPostModel?: MyBuddiesPostModel, options?: any): AxiosPromise<void> {
      return localVarFp.postMyBuddies(myBuddiesPostModel, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} menteeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeMentee(menteeId: number, options?: any): AxiosPromise<void> {
      return localVarFp.removeMentee(menteeId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} mentorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeMentorFromGroup(mentorId: number, options?: any): AxiosPromise<void> {
      return localVarFp.removeMentorFromGroup(mentorId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {UpdateMentorGroupModel} [updateMentorGroupModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMentorGroup(updateMentorGroupModel?: UpdateMentorGroupModel, options?: any): AxiosPromise<void> {
      return localVarFp.updateMentorGroup(updateMentorGroupModel, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} academicYearId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateMenteeImporting(academicYearId: number, options?: any): AxiosPromise<ValidateImportingModel> {
      return localVarFp.validateMenteeImporting(academicYearId, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * MentoringApi - interface
 * @export
 * @interface MentoringApi
 */
export interface MentoringApiInterface {
  /**
   *
   * @param {number} mentorGroupId
   * @param {number} volunteerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MentoringApiInterface
   */
  addMentorToGroup(mentorGroupId: number, volunteerId: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @param {CreateMentorGroupModel} [createMentorGroupModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MentoringApiInterface
   */
  createMentorGroup(createMentorGroupModel?: CreateMentorGroupModel, options?: any): AxiosPromise<number>;

  /**
   *
   * @param {number} mentorGroupId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MentoringApiInterface
   */
  deleteMentorGroup(mentorGroupId: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @param {MenteeFilterModel} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MentoringApiInterface
   */
  filterMentees(filter?: MenteeFilterModel, options?: any): AxiosPromise<MenteeModelGridCollection>;

  /**
   *
   * @summary Filter mentor groups.
   * @param {MentorGroupsFilter} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MentoringApiInterface
   */
  filterMentorGroups(filter?: MentorGroupsFilter, options?: any): AxiosPromise<Array<MentorGroupModel>>;

  /**
   *
   * @param {MentorsFilter} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MentoringApiInterface
   */
  filterMentors(filter?: MentorsFilter, options?: any): AxiosPromise<Array<MentorCollectionModel>>;

  /**
   *
   * @summary Get mentee filtering lookups.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MentoringApiInterface
   */
  getMenteeFilteringLookups(options?: any): AxiosPromise<MenteeLookups>;

  /**
   *
   * @summary Get mentoring groups filtering lookups.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MentoringApiInterface
   */
  getMentorGroupsFilteringLookups(options?: any): AxiosPromise<MentorGroupsLookups>;

  /**
   *
   * @param {MentorGroupsStatsFilter} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MentoringApiInterface
   */
  getMentorGroupsStats(filter?: MentorGroupsStatsFilter, options?: any): AxiosPromise<MentorGroupsStatsModel>;

  /**
   *
   * @param {number} academicYearId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MentoringApiInterface
   */
  getMyBuddies(academicYearId: number, options?: any): AxiosPromise<MyBuddiesFormModel>;

  /**
   *
   * @param {number} academicYearId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MentoringApiInterface
   */
  getMyMentorGroup(academicYearId: number, options?: any): AxiosPromise<MyMentorGroupModel>;

  /**
   *
   * @param {number} academicYearId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MentoringApiInterface
   */
  getPrintoutBatchGroups(academicYearId: number, options?: any): AxiosPromise<Array<PrintoutBatchGroupModel>>;

  /**
   *
   * @param {number} academicYearId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MentoringApiInterface
   */
  importMentees(academicYearId: number, options?: any): AxiosPromise<boolean>;

  /**
   *
   * @param {MyBuddiesPostModel} [myBuddiesPostModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MentoringApiInterface
   */
  postMyBuddies(myBuddiesPostModel?: MyBuddiesPostModel, options?: any): AxiosPromise<void>;

  /**
   *
   * @param {number} menteeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MentoringApiInterface
   */
  removeMentee(menteeId: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @param {number} mentorId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MentoringApiInterface
   */
  removeMentorFromGroup(mentorId: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @param {UpdateMentorGroupModel} [updateMentorGroupModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MentoringApiInterface
   */
  updateMentorGroup(updateMentorGroupModel?: UpdateMentorGroupModel, options?: any): AxiosPromise<void>;

  /**
   *
   * @param {number} academicYearId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MentoringApiInterface
   */
  validateMenteeImporting(academicYearId: number, options?: any): AxiosPromise<ValidateImportingModel>;
}

/**
 * MentoringApi - object-oriented interface
 * @export
 * @class MentoringApi
 * @extends {BaseAPI}
 */
export class MentoringApi extends BaseAPI implements MentoringApiInterface {
  /**
   *
   * @param {number} mentorGroupId
   * @param {number} volunteerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MentoringApi
   */
  public addMentorToGroup(mentorGroupId: number, volunteerId: number, options?: any) {
    return MentoringApiFp(this.configuration)
      .addMentorToGroup(mentorGroupId, volunteerId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CreateMentorGroupModel} [createMentorGroupModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MentoringApi
   */
  public createMentorGroup(createMentorGroupModel?: CreateMentorGroupModel, options?: any) {
    return MentoringApiFp(this.configuration)
      .createMentorGroup(createMentorGroupModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} mentorGroupId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MentoringApi
   */
  public deleteMentorGroup(mentorGroupId: number, options?: any) {
    return MentoringApiFp(this.configuration)
      .deleteMentorGroup(mentorGroupId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {MenteeFilterModel} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MentoringApi
   */
  public filterMentees(filter?: MenteeFilterModel, options?: any) {
    return MentoringApiFp(this.configuration)
      .filterMentees(filter, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Filter mentor groups.
   * @param {MentorGroupsFilter} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MentoringApi
   */
  public filterMentorGroups(filter?: MentorGroupsFilter, options?: any) {
    return MentoringApiFp(this.configuration)
      .filterMentorGroups(filter, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {MentorsFilter} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MentoringApi
   */
  public filterMentors(filter?: MentorsFilter, options?: any) {
    return MentoringApiFp(this.configuration)
      .filterMentors(filter, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get mentee filtering lookups.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MentoringApi
   */
  public getMenteeFilteringLookups(options?: any) {
    return MentoringApiFp(this.configuration)
      .getMenteeFilteringLookups(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get mentoring groups filtering lookups.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MentoringApi
   */
  public getMentorGroupsFilteringLookups(options?: any) {
    return MentoringApiFp(this.configuration)
      .getMentorGroupsFilteringLookups(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {MentorGroupsStatsFilter} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MentoringApi
   */
  public getMentorGroupsStats(filter?: MentorGroupsStatsFilter, options?: any) {
    return MentoringApiFp(this.configuration)
      .getMentorGroupsStats(filter, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} academicYearId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MentoringApi
   */
  public getMyBuddies(academicYearId: number, options?: any) {
    return MentoringApiFp(this.configuration)
      .getMyBuddies(academicYearId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} academicYearId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MentoringApi
   */
  public getMyMentorGroup(academicYearId: number, options?: any) {
    return MentoringApiFp(this.configuration)
      .getMyMentorGroup(academicYearId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} academicYearId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MentoringApi
   */
  public getPrintoutBatchGroups(academicYearId: number, options?: any) {
    return MentoringApiFp(this.configuration)
      .getPrintoutBatchGroups(academicYearId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} academicYearId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MentoringApi
   */
  public importMentees(academicYearId: number, options?: any) {
    return MentoringApiFp(this.configuration)
      .importMentees(academicYearId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {MyBuddiesPostModel} [myBuddiesPostModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MentoringApi
   */
  public postMyBuddies(myBuddiesPostModel?: MyBuddiesPostModel, options?: any) {
    return MentoringApiFp(this.configuration)
      .postMyBuddies(myBuddiesPostModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} menteeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MentoringApi
   */
  public removeMentee(menteeId: number, options?: any) {
    return MentoringApiFp(this.configuration)
      .removeMentee(menteeId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} mentorId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MentoringApi
   */
  public removeMentorFromGroup(mentorId: number, options?: any) {
    return MentoringApiFp(this.configuration)
      .removeMentorFromGroup(mentorId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UpdateMentorGroupModel} [updateMentorGroupModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MentoringApi
   */
  public updateMentorGroup(updateMentorGroupModel?: UpdateMentorGroupModel, options?: any) {
    return MentoringApiFp(this.configuration)
      .updateMentorGroup(updateMentorGroupModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} academicYearId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MentoringApi
   */
  public validateMenteeImporting(academicYearId: number, options?: any) {
    return MentoringApiFp(this.configuration)
      .validateMenteeImporting(academicYearId, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * ReportApi - axios parameter creator
 * @export
 */
export const ReportApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} academicYearId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDashboard: async (academicYearId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'academicYearId' is not null or undefined
      assertParamExists("getDashboard", "academicYearId", academicYearId);
      const localVarPath = `/api/v1/report/dashboard/{academicYearId}`.replace(
        `{${"academicYearId"}}`,
        encodeURIComponent(String(academicYearId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get application form lookups.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDashboardLookups: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/report/dashboard/lookups`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ReportApi - functional programming interface
 * @export
 */
export const ReportApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = ReportApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} academicYearId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDashboard(
      academicYearId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDashboard(academicYearId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get application form lookups.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDashboardLookups(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardLookups>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDashboardLookups(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ReportApi - factory interface
 * @export
 */
export const ReportApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ReportApiFp(configuration);
  return {
    /**
     *
     * @param {number} academicYearId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDashboard(academicYearId: number, options?: any): AxiosPromise<DashboardModel> {
      return localVarFp.getDashboard(academicYearId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get application form lookups.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDashboardLookups(options?: any): AxiosPromise<DashboardLookups> {
      return localVarFp.getDashboardLookups(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * ReportApi - interface
 * @export
 * @interface ReportApi
 */
export interface ReportApiInterface {
  /**
   *
   * @param {number} academicYearId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApiInterface
   */
  getDashboard(academicYearId: number, options?: any): AxiosPromise<DashboardModel>;

  /**
   *
   * @summary Get application form lookups.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApiInterface
   */
  getDashboardLookups(options?: any): AxiosPromise<DashboardLookups>;
}

/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
export class ReportApi extends BaseAPI implements ReportApiInterface {
  /**
   *
   * @param {number} academicYearId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public getDashboard(academicYearId: number, options?: any) {
    return ReportApiFp(this.configuration)
      .getDashboard(academicYearId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get application form lookups.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public getDashboardLookups(options?: any) {
    return ReportApiFp(this.configuration)
      .getDashboardLookups(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * TagsApi - axios parameter creator
 * @export
 */
export const TagsApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTag: async (id: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deleteTag", "id", id);
      const localVarPath = `/api/v1/tags/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllTags: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/tags/all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TagsApi - functional programming interface
 * @export
 */
export const TagsApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = TagsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteTag(
      id: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTag(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllTags(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TagCollectionItemModel>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTags(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * TagsApi - factory interface
 * @export
 */
export const TagsApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = TagsApiFp(configuration);
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteTag(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteTag(id, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllTags(options?: any): AxiosPromise<Array<TagCollectionItemModel>> {
      return localVarFp.getAllTags(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * TagsApi - interface
 * @export
 * @interface TagsApi
 */
export interface TagsApiInterface {
  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagsApiInterface
   */
  deleteTag(id: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagsApiInterface
   */
  getAllTags(options?: any): AxiosPromise<Array<TagCollectionItemModel>>;
}

/**
 * TagsApi - object-oriented interface
 * @export
 * @class TagsApi
 * @extends {BaseAPI}
 */
export class TagsApi extends BaseAPI implements TagsApiInterface {
  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagsApi
   */
  public deleteTag(id: number, options?: any) {
    return TagsApiFp(this.configuration)
      .deleteTag(id, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TagsApi
   */
  public getAllTags(options?: any) {
    return TagsApiFp(this.configuration)
      .getAllTags(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * TrainingApi - axios parameter creator
 * @export
 */
export const TrainingApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    anyAvailableSlotsForThisAcademicYear: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/training/session/slots/any`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} trainingSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelTrainingSession: async (trainingSessionId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'trainingSessionId' is not null or undefined
      assertParamExists("cancelTrainingSession", "trainingSessionId", trainingSessionId);
      const localVarPath = `/api/v1/training/session/{trainingSessionId}/cancel`.replace(
        `{${"trainingSessionId"}}`,
        encodeURIComponent(String(trainingSessionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {TrainingSessionSearchFilterModel} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterTrainingSession: async (
      filter?: TrainingSessionSearchFilterModel,
      options: any = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/training/session/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filter !== undefined) {
        localVarQueryParameter["filter"] = filter;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} academicYearId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getByAcademicYear: async (academicYearId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'academicYearId' is not null or undefined
      assertParamExists("getByAcademicYear", "academicYearId", academicYearId);
      const localVarPath = `/api/v1/training/session/academic/year/{academicYearId}`.replace(
        `{${"academicYearId"}}`,
        encodeURIComponent(String(academicYearId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} trainingSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPrintSignInSheet: async (trainingSessionId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'trainingSessionId' is not null or undefined
      assertParamExists("getPrintSignInSheet", "trainingSessionId", trainingSessionId);
      const localVarPath = `/api/v1/training/session/print/signin/sheet/{trainingSessionId}`.replace(
        `{${"trainingSessionId"}}`,
        encodeURIComponent(String(trainingSessionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTrainingSessionAttendenceLookups: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/training/session/attendance/lookups`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTrainingSessionSaveLookups: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/training/session/save/lookups`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} trainingSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTrainingSessionSaveModel: async (trainingSessionId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'trainingSessionId' is not null or undefined
      assertParamExists("getTrainingSessionSaveModel", "trainingSessionId", trainingSessionId);
      const localVarPath = `/api/v1/training/session/{trainingSessionId}`.replace(
        `{${"trainingSessionId"}}`,
        encodeURIComponent(String(trainingSessionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTrainingSessionSearchLookups: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/training/session/search/lookups`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} trainingSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVolunteerTrainingRecord: async (trainingSessionId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'trainingSessionId' is not null or undefined
      assertParamExists("getVolunteerTrainingRecord", "trainingSessionId", trainingSessionId);
      const localVarPath = `/api/v1/training/session/attendance/{trainingSessionId}`.replace(
        `{${"trainingSessionId"}}`,
        encodeURIComponent(String(trainingSessionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} academicYearId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importTrainingResults: async (academicYearId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'academicYearId' is not null or undefined
      assertParamExists("importTrainingResults", "academicYearId", academicYearId);
      const localVarPath = `/api/v1/training/results/import/{academicYearId}`.replace(
        `{${"academicYearId"}}`,
        encodeURIComponent(String(academicYearId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {TrainingSessionSaveModel} [trainingSessionSaveModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    save: async (trainingSessionSaveModel?: TrainingSessionSaveModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/training/save`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        trainingSessionSaveModel,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} trainingRecordId
     * @param {number} trainingRecordStatusId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTrainingRecordStatus: async (
      trainingRecordId: number,
      trainingRecordStatusId: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'trainingRecordId' is not null or undefined
      assertParamExists("updateTrainingRecordStatus", "trainingRecordId", trainingRecordId);
      // verify required parameter 'trainingRecordStatusId' is not null or undefined
      assertParamExists("updateTrainingRecordStatus", "trainingRecordStatusId", trainingRecordStatusId);
      const localVarPath = `/api/v1/training/session/attendance/update/{trainingRecordId}/{trainingRecordStatusId}`
        .replace(`{${"trainingRecordId"}}`, encodeURIComponent(String(trainingRecordId)))
        .replace(`{${"trainingRecordStatusId"}}`, encodeURIComponent(String(trainingRecordStatusId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateTrainingResultsImporting: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/training/results/import/validate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TrainingApi - functional programming interface
 * @export
 */
export const TrainingApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = TrainingApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async anyAvailableSlotsForThisAcademicYear(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.anyAvailableSlotsForThisAcademicYear(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} trainingSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancelTrainingSession(
      trainingSessionId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cancelTrainingSession(trainingSessionId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {TrainingSessionSearchFilterModel} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filterTrainingSession(
      filter?: TrainingSessionSearchFilterModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainingSessionModelGridCollection>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filterTrainingSession(filter, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} academicYearId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getByAcademicYear(
      academicYearId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SimpleLookupModel>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getByAcademicYear(academicYearId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} trainingSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPrintSignInSheet(
      trainingSessionId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainingSessionPrintSignInSheetModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPrintSignInSheet(trainingSessionId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTrainingSessionAttendenceLookups(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainingSessionAttendenceLookups>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTrainingSessionAttendenceLookups(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTrainingSessionSaveLookups(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainingSessionSaveLookups>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTrainingSessionSaveLookups(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} trainingSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTrainingSessionSaveModel(
      trainingSessionId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainingSessionSaveModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTrainingSessionSaveModel(trainingSessionId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTrainingSessionSearchLookups(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainingSessionSearchLookups>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTrainingSessionSearchLookups(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} trainingSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVolunteerTrainingRecord(
      trainingSessionId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VolunteerTrainingRecordModel>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getVolunteerTrainingRecord(trainingSessionId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} academicYearId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async importTrainingResults(
      academicYearId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.importTrainingResults(academicYearId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {TrainingSessionSaveModel} [trainingSessionSaveModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async save(
      trainingSessionSaveModel?: TrainingSessionSaveModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.save(trainingSessionSaveModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} trainingRecordId
     * @param {number} trainingRecordStatusId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateTrainingRecordStatus(
      trainingRecordId: number,
      trainingRecordStatusId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateTrainingRecordStatus(
        trainingRecordId,
        trainingRecordStatusId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async validateTrainingResultsImporting(
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateImportingModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.validateTrainingResultsImporting(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * TrainingApi - factory interface
 * @export
 */
export const TrainingApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = TrainingApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    anyAvailableSlotsForThisAcademicYear(options?: any): AxiosPromise<boolean> {
      return localVarFp.anyAvailableSlotsForThisAcademicYear(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} trainingSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelTrainingSession(trainingSessionId: number, options?: any): AxiosPromise<boolean> {
      return localVarFp.cancelTrainingSession(trainingSessionId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {TrainingSessionSearchFilterModel} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterTrainingSession(
      filter?: TrainingSessionSearchFilterModel,
      options?: any,
    ): AxiosPromise<TrainingSessionModelGridCollection> {
      return localVarFp.filterTrainingSession(filter, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} academicYearId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getByAcademicYear(academicYearId: number, options?: any): AxiosPromise<Array<SimpleLookupModel>> {
      return localVarFp.getByAcademicYear(academicYearId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} trainingSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPrintSignInSheet(trainingSessionId: number, options?: any): AxiosPromise<TrainingSessionPrintSignInSheetModel> {
      return localVarFp.getPrintSignInSheet(trainingSessionId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTrainingSessionAttendenceLookups(options?: any): AxiosPromise<TrainingSessionAttendenceLookups> {
      return localVarFp.getTrainingSessionAttendenceLookups(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTrainingSessionSaveLookups(options?: any): AxiosPromise<TrainingSessionSaveLookups> {
      return localVarFp.getTrainingSessionSaveLookups(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} trainingSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTrainingSessionSaveModel(trainingSessionId: number, options?: any): AxiosPromise<TrainingSessionSaveModel> {
      return localVarFp
        .getTrainingSessionSaveModel(trainingSessionId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTrainingSessionSearchLookups(options?: any): AxiosPromise<TrainingSessionSearchLookups> {
      return localVarFp.getTrainingSessionSearchLookups(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} trainingSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVolunteerTrainingRecord(
      trainingSessionId: number,
      options?: any,
    ): AxiosPromise<Array<VolunteerTrainingRecordModel>> {
      return localVarFp
        .getVolunteerTrainingRecord(trainingSessionId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} academicYearId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importTrainingResults(academicYearId: number, options?: any): AxiosPromise<boolean> {
      return localVarFp.importTrainingResults(academicYearId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {TrainingSessionSaveModel} [trainingSessionSaveModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    save(trainingSessionSaveModel?: TrainingSessionSaveModel, options?: any): AxiosPromise<boolean> {
      return localVarFp.save(trainingSessionSaveModel, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} trainingRecordId
     * @param {number} trainingRecordStatusId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTrainingRecordStatus(
      trainingRecordId: number,
      trainingRecordStatusId: number,
      options?: any,
    ): AxiosPromise<boolean> {
      return localVarFp
        .updateTrainingRecordStatus(trainingRecordId, trainingRecordStatusId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateTrainingResultsImporting(options?: any): AxiosPromise<ValidateImportingModel> {
      return localVarFp.validateTrainingResultsImporting(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * TrainingApi - interface
 * @export
 * @interface TrainingApi
 */
export interface TrainingApiInterface {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingApiInterface
   */
  anyAvailableSlotsForThisAcademicYear(options?: any): AxiosPromise<boolean>;

  /**
   *
   * @param {number} trainingSessionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingApiInterface
   */
  cancelTrainingSession(trainingSessionId: number, options?: any): AxiosPromise<boolean>;

  /**
   *
   * @param {TrainingSessionSearchFilterModel} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingApiInterface
   */
  filterTrainingSession(
    filter?: TrainingSessionSearchFilterModel,
    options?: any,
  ): AxiosPromise<TrainingSessionModelGridCollection>;

  /**
   *
   * @param {number} academicYearId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingApiInterface
   */
  getByAcademicYear(academicYearId: number, options?: any): AxiosPromise<Array<SimpleLookupModel>>;

  /**
   *
   * @param {number} trainingSessionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingApiInterface
   */
  getPrintSignInSheet(trainingSessionId: number, options?: any): AxiosPromise<TrainingSessionPrintSignInSheetModel>;

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingApiInterface
   */
  getTrainingSessionAttendenceLookups(options?: any): AxiosPromise<TrainingSessionAttendenceLookups>;

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingApiInterface
   */
  getTrainingSessionSaveLookups(options?: any): AxiosPromise<TrainingSessionSaveLookups>;

  /**
   *
   * @param {number} trainingSessionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingApiInterface
   */
  getTrainingSessionSaveModel(trainingSessionId: number, options?: any): AxiosPromise<TrainingSessionSaveModel>;

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingApiInterface
   */
  getTrainingSessionSearchLookups(options?: any): AxiosPromise<TrainingSessionSearchLookups>;

  /**
   *
   * @param {number} trainingSessionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingApiInterface
   */
  getVolunteerTrainingRecord(
    trainingSessionId: number,
    options?: any,
  ): AxiosPromise<Array<VolunteerTrainingRecordModel>>;

  /**
   *
   * @param {number} academicYearId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingApiInterface
   */
  importTrainingResults(academicYearId: number, options?: any): AxiosPromise<boolean>;

  /**
   *
   * @param {TrainingSessionSaveModel} [trainingSessionSaveModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingApiInterface
   */
  save(trainingSessionSaveModel?: TrainingSessionSaveModel, options?: any): AxiosPromise<boolean>;

  /**
   *
   * @param {number} trainingRecordId
   * @param {number} trainingRecordStatusId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingApiInterface
   */
  updateTrainingRecordStatus(
    trainingRecordId: number,
    trainingRecordStatusId: number,
    options?: any,
  ): AxiosPromise<boolean>;

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingApiInterface
   */
  validateTrainingResultsImporting(options?: any): AxiosPromise<ValidateImportingModel>;
}

/**
 * TrainingApi - object-oriented interface
 * @export
 * @class TrainingApi
 * @extends {BaseAPI}
 */
export class TrainingApi extends BaseAPI implements TrainingApiInterface {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingApi
   */
  public anyAvailableSlotsForThisAcademicYear(options?: any) {
    return TrainingApiFp(this.configuration)
      .anyAvailableSlotsForThisAcademicYear(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} trainingSessionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingApi
   */
  public cancelTrainingSession(trainingSessionId: number, options?: any) {
    return TrainingApiFp(this.configuration)
      .cancelTrainingSession(trainingSessionId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {TrainingSessionSearchFilterModel} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingApi
   */
  public filterTrainingSession(filter?: TrainingSessionSearchFilterModel, options?: any) {
    return TrainingApiFp(this.configuration)
      .filterTrainingSession(filter, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} academicYearId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingApi
   */
  public getByAcademicYear(academicYearId: number, options?: any) {
    return TrainingApiFp(this.configuration)
      .getByAcademicYear(academicYearId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} trainingSessionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingApi
   */
  public getPrintSignInSheet(trainingSessionId: number, options?: any) {
    return TrainingApiFp(this.configuration)
      .getPrintSignInSheet(trainingSessionId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingApi
   */
  public getTrainingSessionAttendenceLookups(options?: any) {
    return TrainingApiFp(this.configuration)
      .getTrainingSessionAttendenceLookups(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingApi
   */
  public getTrainingSessionSaveLookups(options?: any) {
    return TrainingApiFp(this.configuration)
      .getTrainingSessionSaveLookups(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} trainingSessionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingApi
   */
  public getTrainingSessionSaveModel(trainingSessionId: number, options?: any) {
    return TrainingApiFp(this.configuration)
      .getTrainingSessionSaveModel(trainingSessionId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingApi
   */
  public getTrainingSessionSearchLookups(options?: any) {
    return TrainingApiFp(this.configuration)
      .getTrainingSessionSearchLookups(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} trainingSessionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingApi
   */
  public getVolunteerTrainingRecord(trainingSessionId: number, options?: any) {
    return TrainingApiFp(this.configuration)
      .getVolunteerTrainingRecord(trainingSessionId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} academicYearId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingApi
   */
  public importTrainingResults(academicYearId: number, options?: any) {
    return TrainingApiFp(this.configuration)
      .importTrainingResults(academicYearId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {TrainingSessionSaveModel} [trainingSessionSaveModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingApi
   */
  public save(trainingSessionSaveModel?: TrainingSessionSaveModel, options?: any) {
    return TrainingApiFp(this.configuration)
      .save(trainingSessionSaveModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} trainingRecordId
   * @param {number} trainingRecordStatusId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingApi
   */
  public updateTrainingRecordStatus(trainingRecordId: number, trainingRecordStatusId: number, options?: any) {
    return TrainingApiFp(this.configuration)
      .updateTrainingRecordStatus(trainingRecordId, trainingRecordStatusId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TrainingApi
   */
  public validateTrainingResultsImporting(options?: any) {
    return TrainingApiFp(this.configuration)
      .validateTrainingResultsImporting(options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} trainingSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeTrainingSessionSlot: async (trainingSessionId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'trainingSessionId' is not null or undefined
      assertParamExists("changeTrainingSessionSlot", "trainingSessionId", trainingSessionId);
      const localVarPath = `/api/v1/users/training/changeSlot/{trainingSessionId}`.replace(
        `{${"trainingSessionId"}}`,
        encodeURIComponent(String(trainingSessionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {GridFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterUsers: async (filter?: GridFilter, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/users/filter`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filter !== undefined) {
        localVarQueryParameter["filter"] = filter;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProfile: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/users/profile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProfileByUserId: async (userId: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("getProfileByUserId", "userId", userId);
      const localVarPath = `/api/v1/users/profile/{userId}`.replace(
        `{${"userId"}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get user activity form lookups.
     * @param {boolean} selectPastAcademicYears
     * @param {boolean} selectWithdrawnDeclined
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserLookups: async (
      selectPastAcademicYears: boolean,
      selectWithdrawnDeclined: boolean,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'selectPastAcademicYears' is not null or undefined
      assertParamExists("getUserLookups", "selectPastAcademicYears", selectPastAcademicYears);
      // verify required parameter 'selectWithdrawnDeclined' is not null or undefined
      assertParamExists("getUserLookups", "selectWithdrawnDeclined", selectWithdrawnDeclined);
      const localVarPath = `/api/v1/users/lookups/{selectPastAcademicYears}/{selectWithdrawnDeclined}`
        .replace(`{${"selectPastAcademicYears"}}`, encodeURIComponent(String(selectPastAcademicYears)))
        .replace(`{${"selectWithdrawnDeclined"}}`, encodeURIComponent(String(selectWithdrawnDeclined)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gets user training sessions.
     * @param {number} userId User id.
     * @param {number} academicYearId Academic year id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserTrainingSessions: async (
      userId: number,
      academicYearId: number,
      options: any = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("getUserTrainingSessions", "userId", userId);
      // verify required parameter 'academicYearId' is not null or undefined
      assertParamExists("getUserTrainingSessions", "academicYearId", academicYearId);
      const localVarPath = `/api/v1/users/training/{userId}/academic/year/{academicYearId}`
        .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
        .replace(`{${"academicYearId"}}`, encodeURIComponent(String(academicYearId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UserModel} [userModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProfile: async (userModel?: UserModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/users/profile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(userModel, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {VolunteerWithdrawModel} [volunteerWithdrawModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    withdraw: async (volunteerWithdrawModel?: VolunteerWithdrawModel, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/users/training/withdraw`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        volunteerWithdrawModel,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} trainingSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async changeTrainingSessionSlot(
      trainingSessionId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.changeTrainingSessionSlot(trainingSessionId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {GridFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async filterUsers(
      filter?: GridFilter,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModelGridCollection>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.filterUsers(filter, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProfile(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProfile(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProfileByUserId(
      userId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProfileByUserId(userId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get user activity form lookups.
     * @param {boolean} selectPastAcademicYears
     * @param {boolean} selectWithdrawnDeclined
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserLookups(
      selectPastAcademicYears: boolean,
      selectWithdrawnDeclined: boolean,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserLookups>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUserLookups(
        selectPastAcademicYears,
        selectWithdrawnDeclined,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Gets user training sessions.
     * @param {number} userId User id.
     * @param {number} academicYearId Academic year id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserTrainingSessions(
      userId: number,
      academicYearId: number,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserTrainingModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUserTrainingSessions(
        userId,
        academicYearId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {UserModel} [userModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateProfile(
      userModel?: UserModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateProfile(userModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {VolunteerWithdrawModel} [volunteerWithdrawModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async withdraw(
      volunteerWithdrawModel?: VolunteerWithdrawModel,
      options?: any,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.withdraw(volunteerWithdrawModel, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = UsersApiFp(configuration);
  return {
    /**
     *
     * @param {number} trainingSessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeTrainingSessionSlot(trainingSessionId: number, options?: any): AxiosPromise<void> {
      return localVarFp.changeTrainingSessionSlot(trainingSessionId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {GridFilter} [filter]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    filterUsers(filter?: GridFilter, options?: any): AxiosPromise<UserModelGridCollection> {
      return localVarFp.filterUsers(filter, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProfile(options?: any): AxiosPromise<UserModel> {
      return localVarFp.getProfile(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProfileByUserId(userId: number, options?: any): AxiosPromise<UserModel> {
      return localVarFp.getProfileByUserId(userId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get user activity form lookups.
     * @param {boolean} selectPastAcademicYears
     * @param {boolean} selectWithdrawnDeclined
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserLookups(
      selectPastAcademicYears: boolean,
      selectWithdrawnDeclined: boolean,
      options?: any,
    ): AxiosPromise<UserLookups> {
      return localVarFp
        .getUserLookups(selectPastAcademicYears, selectWithdrawnDeclined, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Gets user training sessions.
     * @param {number} userId User id.
     * @param {number} academicYearId Academic year id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserTrainingSessions(userId: number, academicYearId: number, options?: any): AxiosPromise<UserTrainingModel> {
      return localVarFp
        .getUserTrainingSessions(userId, academicYearId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {UserModel} [userModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProfile(userModel?: UserModel, options?: any): AxiosPromise<UserModel> {
      return localVarFp.updateProfile(userModel, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {VolunteerWithdrawModel} [volunteerWithdrawModel]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    withdraw(volunteerWithdrawModel?: VolunteerWithdrawModel, options?: any): AxiosPromise<void> {
      return localVarFp.withdraw(volunteerWithdrawModel, options).then(request => request(axios, basePath));
    },
  };
};

/**
 * UsersApi - interface
 * @export
 * @interface UsersApi
 */
export interface UsersApiInterface {
  /**
   *
   * @param {number} trainingSessionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  changeTrainingSessionSlot(trainingSessionId: number, options?: any): AxiosPromise<void>;

  /**
   *
   * @param {GridFilter} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  filterUsers(filter?: GridFilter, options?: any): AxiosPromise<UserModelGridCollection>;

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  getProfile(options?: any): AxiosPromise<UserModel>;

  /**
   *
   * @param {number} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  getProfileByUserId(userId: number, options?: any): AxiosPromise<UserModel>;

  /**
   *
   * @summary Get user activity form lookups.
   * @param {boolean} selectPastAcademicYears
   * @param {boolean} selectWithdrawnDeclined
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  getUserLookups(
    selectPastAcademicYears: boolean,
    selectWithdrawnDeclined: boolean,
    options?: any,
  ): AxiosPromise<UserLookups>;

  /**
   *
   * @summary Gets user training sessions.
   * @param {number} userId User id.
   * @param {number} academicYearId Academic year id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  getUserTrainingSessions(userId: number, academicYearId: number, options?: any): AxiosPromise<UserTrainingModel>;

  /**
   *
   * @param {UserModel} [userModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  updateProfile(userModel?: UserModel, options?: any): AxiosPromise<UserModel>;

  /**
   *
   * @param {VolunteerWithdrawModel} [volunteerWithdrawModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApiInterface
   */
  withdraw(volunteerWithdrawModel?: VolunteerWithdrawModel, options?: any): AxiosPromise<void>;
}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI implements UsersApiInterface {
  /**
   *
   * @param {number} trainingSessionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public changeTrainingSessionSlot(trainingSessionId: number, options?: any) {
    return UsersApiFp(this.configuration)
      .changeTrainingSessionSlot(trainingSessionId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {GridFilter} [filter]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public filterUsers(filter?: GridFilter, options?: any) {
    return UsersApiFp(this.configuration)
      .filterUsers(filter, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getProfile(options?: any) {
    return UsersApiFp(this.configuration)
      .getProfile(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} userId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getProfileByUserId(userId: number, options?: any) {
    return UsersApiFp(this.configuration)
      .getProfileByUserId(userId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get user activity form lookups.
   * @param {boolean} selectPastAcademicYears
   * @param {boolean} selectWithdrawnDeclined
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getUserLookups(selectPastAcademicYears: boolean, selectWithdrawnDeclined: boolean, options?: any) {
    return UsersApiFp(this.configuration)
      .getUserLookups(selectPastAcademicYears, selectWithdrawnDeclined, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Gets user training sessions.
   * @param {number} userId User id.
   * @param {number} academicYearId Academic year id.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getUserTrainingSessions(userId: number, academicYearId: number, options?: any) {
    return UsersApiFp(this.configuration)
      .getUserTrainingSessions(userId, academicYearId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UserModel} [userModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public updateProfile(userModel?: UserModel, options?: any) {
    return UsersApiFp(this.configuration)
      .updateProfile(userModel, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {VolunteerWithdrawModel} [volunteerWithdrawModel]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public withdraw(volunteerWithdrawModel?: VolunteerWithdrawModel, options?: any) {
    return UsersApiFp(this.configuration)
      .withdraw(volunteerWithdrawModel, options)
      .then(request => request(this.axios, this.basePath));
  }
}
