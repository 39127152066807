










import { Component, Vue } from "vue-property-decorator";

import { SnackbarCpt, OverlayCpt } from "@/core/components/common/notification";

@Component({
  components: {
    SnackbarCpt,
    OverlayCpt,
  },
})
export default class App extends Vue {
  private isTokenRefreshingInProcess = false;
  get layout() {
    const layout = this.$route.meta.layout || "default";
    return `${layout}-layout`;
  }

  async created() {
    // tslint:disable-next-line:no-console
    console.log(`${process.env.VUE_APP_ENVIRONMENT} - ${process.env.VUE_APP_VERSION}`);

    this.$eventHub.$on("USER_UPDATED", () => {
      this.isTokenRefreshingInProcess = false;
      this.$forceUpdate();
      this.enforceLoginRedirects();
    });
    this.$eventHub.$off("TOKEN_ABOUT_TO_EXPIRE");
    this.$eventHub.$on("TOKEN_ABOUT_TO_EXPIRE", async () => {
      if (!this.isTokenRefreshingInProcess) {
        this.isTokenRefreshingInProcess = true;
        await this.$msal.getTokenRedirect();
      }
    });
  }

  private enforceLoginRedirects() {
    // NOTE: The actual re-routing logic is in router.ts, but here we
    // need to trigger the rules to be applied upon USER_UPDATED

    if (this.$auth.isAuthenticated) {
      if (this.$route.fullPath.startsWith("/welcome#code")) {
        // SEE: https://stackoverflow.com/a/47005895/413785
        this.$router.go(undefined);
      }
    }
  }
}
