






















import { Component } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";

@Component({
  components: {},
})
export default class SnackbarCpt extends AppVue {
  snackbar = false;
  text = "";
  color = "";

  async created() {
    this.$eventHub.$off("SNACK-BAR-SHOW");

    // On grid interaction, update the route and re-fetch data
    this.$eventHub.$on("SNACK-BAR-SHOW", (data: any) => {
      this.text = data.text;
      this.color = data.color;
      this.snackbar = true;
    });
  }
}
