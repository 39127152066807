// eslint-disable-next-line
export class Map<T> {
  [key: string]: T;
}

/**
 * All the form models that are to be submitted to the WebAPI and validated
 * by it should inherit the Validatable to be able to use the generic form
 * components and benefit from the seamless validation error message resolving.
 */
export class Validatable {
  /**
   * Model state object that gets returned from the WebAPI. If the modelState is
   * valid, modelState will be NULL. If not, it will contain a dictionary (map)
   * of property keys and error messages associated to each property.
   */
  modelState?: Map<string[] | null>;

  constructor() {
    this.modelState = {};
  }
}

export class BooleanLookupModel {
  id?: boolean;
  text: string;

  constructor(data?: Partial<BooleanLookupModel>) {
    this.text = "";
    this.id = null;
    if (data) {
      Object.assign(this, data);
    }
  }
}

export class DateTimeModel extends Validatable {
  date: string;
  time: string;

  constructor(data?: Partial<DateTimeModel>) {
    super();
    this.date = "";
    this.time = "";
    if (data) {
      Object.assign(this, data);
    }
  }
}
