
import { Prop } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";
import { Validatable } from "@/core/models";

export default class BaseInputComponent extends AppVue {
  @Prop() model: Validatable;
  @Prop() prop: string;
  @Prop() label?: string;
  @Prop() placeholder?: string;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: false }) required: boolean;
  @Prop({ default: false }) loading: boolean;
  @Prop({ default: "" }) hint: string;

  get modelStateMessage() {
    if (!this.prop || !this.model || !this.model.modelState) {
      return "";
    }

    const messages = this.model.modelState[this.prop];

    if (!messages || messages.length === 0) {
      return "";
    }

    return messages[0] || "";
  }

  get modelStateMessages() {
    return this.modelStateMessage ? [this.modelStateMessage] : [];
  }

  onChange(value: any) {
    this.$emit("change", value);

    if (!this.prop || !this.model || !this.model.modelState) {
      return;
    }

    this.model.modelState[this.prop] = null as any;
  }
}
