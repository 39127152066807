import { AxiosFactory } from "@/core/services/common";
import {
  Configuration,
  AccountApiFactory,
  UsersApiFactory,
  EmailApiFactory,
  ApplicationApiFactory,
  ReportApiFactory,
  AuditRecordApiFactory,
  TrainingApiFactory,
  MentoringApiFactory,
  AzurePermissionsApiFactory,
  TagsApiFactory,
} from "@/core/webapi";

function buildArgs() {
  return [new Configuration({ basePath: process.env.VUE_APP_WEBAPI_URL }), undefined, AxiosFactory.create()] as any[];
}

export const ApiService = {
  account: () => AccountApiFactory(...buildArgs()),
  user: () => UsersApiFactory(...buildArgs()),
  email: () => EmailApiFactory(...buildArgs()),
  application: () => ApplicationApiFactory(...buildArgs()),
  report: () => ReportApiFactory(...buildArgs()),
  audit: () => AuditRecordApiFactory(...buildArgs()),
  training: () => TrainingApiFactory(...buildArgs()),
  mentoring: () => MentoringApiFactory(...buildArgs()),
  azurePermissions: () => AzurePermissionsApiFactory(...buildArgs()),
  tags: () => TagsApiFactory(...buildArgs()),
};
