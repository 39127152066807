/* eslint-disable */
export enum ApplicationStatus {
    ApplicationSubmitted = 1,
    ApplicationQueried = 2,
    AwaitingExperientialTraining = 3,
    AwaitingBlackboardTraining = 4,
    Trained = 5,
    Withdrawn = 6,
    Declined = 7,
}
