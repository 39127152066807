import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import VueAuthenticate from "vue-authenticate";
import VueMoment from "vue-moment";
import moment from "moment";

import App from "@/App.vue";
import vuetify from "@/core/plugins/vuetify";
import MsalPlugin from "@/core/plugins/vue.msal.plugin";
import router from "@/router";
import store from "@/core/store";

import { Default, Empty } from "@/layouts";

moment.locale("en-ie");

Vue.component("default-layout", Default);
Vue.component("empty-layout", Empty);
Vue.config.productionTip = false;
Vue.prototype.$eventHub = new Vue();

// SEE: https://stackoverflow.com/a/32540840/413785
function toLocalTime(value: string) {
  return moment(moment.utc(value).toDate()).local();
}
Vue.filter("formatDate", (value: string, format: string) => (!value ? "" : toLocalTime(value).format(format)));
Vue.filter("formatUtcDate", (value: string, format: string) => (!value ? "" : moment.utc(value).format(format)));
Vue.filter("fromNow", (value: string) => (!value ? "" : toLocalTime(value).fromNow()));

Vue.use(VueAxios, axios);
Vue.use(VueAuthenticate, {
  baseUrl: process.env.VUE_APP_WEBAPI_URL,
  loginUrl: "/welcome",
  tokenPrefix: "auth",
  tokenName: "token",
});
Vue.use(VueMoment);
Vue.use(MsalPlugin, {});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount("#app");
