import Vue from "vue";
import * as msal from "@azure/msal-browser";
import router from "@/router";
import { LoginModel } from "@/core/webapi";
import { AccountService } from "@/core/services";
import { NotificationProvider } from "./common";

export class AuthService {
  private account: msal.AccountInfo | null;
  private scopes: string[];
  private loginRedirectRequest: msal.RedirectRequest;
  private msalInstance: msal.PublicClientApplication;
  private cancelErrorCode = "AADB2C90091";
  private passwordResetErorCode = "AADB2C90118";

  constructor() {
    this.msalInstance = new msal.PublicClientApplication(this.getConfig());
    this.scopes = ["User.Read"];

    this.loginRedirectRequest = {
      scopes: this.scopes,
      redirectStartPage: window.location.href,
    };

    window.addEventListener("load", async () => {
      this.loadAuthModule();
    });
  }

  getConfig() {
    return {
      auth: {
        clientId: process.env.VUE_APP_AUTH_CLIENT_ID,
        authority: process.env.VUE_APP_AUTH_AUTHORITY,
        redirectUri: `${process.env.VUE_APP_WEBAPP_URL}/welcome`,
        navigateToLoginRequestUrl: false,
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
      },
      system: {
        loggerOptions: {
          loggerCallback: (level: msal.LogLevel, message: any, containsPii: any) => {
            if (containsPii) {
              return;
            }
            switch (level) {
              case msal.LogLevel.Error:
                console.error(message);
                return;
              case msal.LogLevel.Info:
                console.info(message);
                return;
              case msal.LogLevel.Verbose:
                console.debug(message);
                return;
              case msal.LogLevel.Warning:
                console.warn(message);
                return;
              default:
                console.log(message);
            }
          },
        },
      },
    } as msal.Configuration;
  }

  loadAuthModule(): void {
    this.msalInstance
      .handleRedirectPromise()
      .then((resp: msal.AuthenticationResult | null) => {
        this.handleResponse(resp);
      })
      .catch((error: any) => {
        if (error && error instanceof msal.AuthError) {
          const authError = error as msal.AuthError;

          if (this.isOfErrorCode(authError, this.cancelErrorCode)) {
            // The user has clicked "Cancel" on B2C policy form
            this.goToDefaultRoute();
            return;
          }

          if (this.isOfErrorCode(authError, this.passwordResetErorCode)) {
            // User has requested a password reset - redirect them to tha correct policy
            this.passwordReset();
            return;
          }
        }

        // Log the error
        console.error(error);
      });
  }

  isOfErrorCode(error: msal.AuthError, errorCode: string) {
    return error.errorCode === "access_denied" && error.errorMessage.startsWith(`${errorCode}:`);
  }

  passwordReset(): void {
    const passwordResetRequest: msal.RedirectRequest = {
      scopes: this.scopes,
      authority: process.env.VUE_APP_AUTH_AUTHORITY,
      redirectStartPage: "/welcome",
    };
    this.msalInstance.loginRedirect(passwordResetRequest);
  }

  /**
   * Redirects user to the default route. The default route depends on
   * whether the user is logged in or not
   */
  goToDefaultRoute() {
    router.push("/welcome");
  }
  /**
   * Handles the response from a popup or redirect. If response is null, will check if we have any
   * accounts and attempt to sign in.
   * @param response
   */
  private async handleResponse(response: msal.AuthenticationResult | null) {
    if (response) {
      this.setAccount(response.account);
      await this.setToken(response.accessToken);
    } else {
      this.setAccount(this.getAccount());
      await this.validateAuthentication();
    }
  }

  setAccount(account: msal.AccountInfo | null) {
    if (this.account !== account) {
      this.account = account;
    }
  }

  getAccount(): msal.AccountInfo | null {
    // need to call getAccount here?
    const currentAccounts = this.msalInstance.getAllAccounts();
    if (currentAccounts === null) {
      this.setAccount(null);
      return null;
    }

    if (currentAccounts.length >= 1) {
      if (currentAccounts.length > 1) {
        // Add choose account code here
        console.warn("Multiple accounts detected, need to add choose account code.");
      }

      if (!this.account) {
        this.setAccount(currentAccounts[0]);
      }
      return currentAccounts[0];
    }
    this.setAccount(null);
    return null;
  }

  async validateAuthentication() {
    // if isAuthenticated in Microsoft and odyssey return true
    if (this.isMicrosoftAuthenticated() && this.isOdysseyAuthenticated()) {
      return true;
    }

    // if isMicrosoftAuthenticated but not in odyssey get microsoft token
    //  and authenticate against odyssey and return true
    if (this.isMicrosoftAuthenticated() && !this.isOdysseyAuthenticated()) {
      await this.getTokenRedirect();
      return true;
    }

    // if isOdysseyAuthenticated but not in microsoft log out from Odyssey
    // and return false, because it is not authenticated
    if (!this.isMicrosoftAuthenticated() && this.isOdysseyAuthenticated()) {
      AccountService.logout();
    }

    return false;
  }

  async login() {
    if (await this.validateAuthentication()) {
      return;
    }

    this.msalInstance.loginRedirect(this.loginRedirectRequest);
  }

  isAuthenticated(): boolean {
    return this.isMicrosoftAuthenticated() && this.isOdysseyAuthenticated();
  }

  isOdysseyAuthenticated() {
    return Vue.prototype.$auth.isAuthenticated();
  }

  isMicrosoftAuthenticated() {
    return this.account != null || this.getAccount() !== null;
  }

  getLoggedInAccount(): msal.AccountInfo {
    const account = this.getAccount();
    if (account === null) {
      throw new Error("User is not currently logged in");
    }

    return account;
  }

  async logout() {
    if (!(await this.validateAuthentication())) {
      return;
    }

    const logOutRequest: msal.EndSessionRequest = {
      account: this.getLoggedInAccount(),
      postLogoutRedirectUri: process?.env?.VUE_APP_WEBAPP_URL,
    };

    this.setAccount(null);
    if (this.isOdysseyAuthenticated()) {
      AccountService.logout();
    }

    if (process?.env?.VUE_APP_ENVIRONMENT === "Production") {
      await this.msalInstance.logout(logOutRequest);
    }

    localStorage.clear();
    this.goToDefaultRoute();
  }

  async setToken(accessToken: string) {
    const model: LoginModel = {
      token: accessToken,
    };
    await NotificationProvider.loadingStart("Validating user");
    try {
      await AccountService.login(model);
      await NotificationProvider.loadingStop();
    } catch (e) {
      console.error("Error to login", e);
      const message = e?.response?.data?.message;
      await NotificationProvider.loadingStop();
      NotificationProvider.error(
        message ?? "Sorry! Something went wrong during sign-in. Please contact the administrator",
      );
      setTimeout(async () => {
        // if Odyssey login fails we logout from Microsoft
        await this.logout();
      }, 5000);
    }
  }

  async getTokenRedirect(): Promise<string> {
    try {
      const silentRequest: msal.SilentRequest = {
        scopes: this.scopes,
        forceRefresh: false,
        account: this.getLoggedInAccount(),
      };
      const response = await this.msalInstance.acquireTokenSilent(silentRequest);

      await this.setToken(response.accessToken);
      return response.accessToken;
    } catch (e) {
      if (e instanceof msal.InteractionRequiredAuthError) {
        this.msalInstance.acquireTokenRedirect(this.loginRedirectRequest).catch((err: any) => console.error(err));
      } else {
        console.error(e);
      }
    }

    return "";
  }
}
