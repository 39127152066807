import { Vue } from "vue-property-decorator";

export const NotificationProvider = new Vue({
  methods: {
    async notifyColor(text: string, color: string) {
      this.$eventHub.$emit("SNACK-BAR-SHOW", { text, color });
    },
    async success(text: string) {
      this.notifyColor(text, "success");
    },
    async error(text: string) {
      this.notifyColor(text, "error");
    },
    async info(text: string) {
      this.notifyColor(text, "info");
    },
    async warning(text: string) {
      this.notifyColor(text, "warning");
    },
    async notify(text: string) {
      this.notifyColor(text, "");
    },
    async loadingStart(message = "") {
      this.$eventHub.$emit("OVERLAY-SHOW", true, message);
    },
    async loadingStop() {
      this.$eventHub.$emit("OVERLAY-SHOW", false, "");
    },
  },
});
