export const trainingRoutes = [
  {
    path: "/training-session-search",
    name: "training-session-search",
    meta: { onlyAdmin: true },
    component: () => import(/* webpackChunkName: "training" */ "./TrainingSessionSearchView.vue"),
  },
  {
    path: "/training-session-edit/:trainingSessionId?",
    name: "training-session-edit",
    meta: { onlyAdmin: true },
    component: () => import(/* webpackChunkName: "training" */ "./TrainingSessionEditView.vue"),
  },
];
