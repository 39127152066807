



























import { Component, Prop } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";

@Component({
  components: {},
})
export default class ButtonCpt extends AppVue {
  @Prop({ required: false, default: false }) loading: boolean;
  @Prop({ required: false }) to: any;
  @Prop({ required: false, default: "primary" }) color: string;
  @Prop({ required: false, default: false }) xSmall: boolean;
  @Prop({ required: false, default: false }) small: boolean;
  @Prop({ required: false, default: false }) large: boolean;
  @Prop({ required: false, default: false }) xLarge: boolean;
  @Prop({ required: false, default: false }) block: boolean;
  @Prop({ required: false, default: null }) icon: string;
  @Prop({ required: false, default: false }) text: boolean;
  @Prop({ required: false, default: "button" }) type: string;
  @Prop({ required: false, default: false }) disabled: boolean;
  @Prop({ required: false, default: true }) iconLeft: boolean;
  @Prop({ required: false, default: false }) outlined: boolean;
  @Prop({ required: false, default: true }) depressed: boolean;

  async created() {
    //
  }

  async onClick() {
    this.$emit("click");
  }
}
