




















import { Component, Prop } from "vue-property-decorator";
import BaseSelectableInputComponent from "./BaseSelectableInputComponent.vue";

@Component({
  components: {},
})
export default class InputAutocompleteCpt extends BaseSelectableInputComponent {
  @Prop() autofocus?: boolean;
  @Prop({ default: false }) multiple: boolean;
  @Prop({ default: false }) clearable: boolean;

  onChangedInternal(value: any) {
    this.onChange(value);
  }
}
