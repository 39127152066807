import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#1B79B7",
        success: "#019866",
        error: "#981b01",
        warning: "#986601",
      },
    },
  },
});
