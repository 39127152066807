import { AuthService } from "@/core/services/auth.service";
import { IMsalPlugin } from "./IMsalPlugin";

export default class MsalPlugin {
  static install(Vue: any) {
    Vue.prototype.$msal = new MsalPlugin();
  }

  constructor() {
    const authService = new AuthService();

    const exposed: IMsalPlugin = {
      isAuthenticated() {
        return authService.isAuthenticated();
      },
      async login() {
        await authService.login();
      },
      async validateAuthentication() {
        return await authService.validateAuthentication();
      },
      async logout() {
        await authService.logout();
      },
      async getTokenRedirect() {
        return await authService.getTokenRedirect();
      },
      getAccount() {
        return authService.getAccount();
      },
      getLoggedInAccount() {
        return authService.getLoggedInAccount();
      },
      getStatus() {
        return `S ${this.isAuthenticated()}`;
      },
    };

    return exposed;
  }
}
