import { Vue } from "vue-property-decorator";

export const PrintService = new Vue({
  methods: {
    print(elementId: string, title: string) {
      const winPrint = window.open("", "", "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0");

      winPrint.document.write(this.getPrintHtmlWithStyles(elementId));
      winPrint.document.title = title;

      winPrint.document.close();
      winPrint.focus();
      // That is the only to make it work on Chrome/Firefox/Edge
      // Using a function with a setTimeout in it
      this.onPrintFinished(winPrint, winPrint.print());
    },

    // Function which will be called after printing/cancelling printing
    // eslint-disable-next-line
    onPrintFinished(winPrint: Window, printed: any) {
      setTimeout(() => {
        winPrint.close();
      }, 700);
    },

    getPrintHtmlWithStyles(elementId: string) {
      const prtHtml = document.getElementById(elementId).innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [...document.querySelectorAll("link[rel='stylesheet'], style")]) {
        stylesHtml += node.outerHTML;
      }

      // Return styles and body
      return `
      <!DOCTYPE html>
      <html>
        <head>
          ${stylesHtml}
        </head>
        <body>
          ${prtHtml}
        </body>
      </html>
    `;
    },
  },
});
