


















import { Component, Prop } from "vue-property-decorator";
import { BaseInputComponent } from ".";

@Component({
  components: {},
})
export default class InputTextareaCpt extends BaseInputComponent {
  @Prop() autofocus?: boolean;
  @Prop({ default: 3 }) rows: number;
  @Prop({ default: false }) counter: boolean | number;

  onChangedInternal(value: any) {
    this.onChange(value);
  }
}
