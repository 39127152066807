export const applicationRoutes = [
  {
    path: "/application",
    name: "application",
    meta: { onlyVolunteer: true },
    component: () => import(/* webpackChunkName: "application" */ "./ApplicationView.vue"),
  },
  {
    path: "/application-complete",
    name: "application-complete",
    meta: { onlyVolunteer: true },
    component: () => import(/* webpackChunkName: "application" */ "./ApplicationCompleteView.vue"),
  },
  {
    path: "/volunteer-search",
    name: "volunteer-search",
    meta: { onlyAdmin: true },
    component: () => import(/* webpackChunkName: "application" */ "./VolunteerSearchView.vue"),
  },
  {
    path: "/volunteer-details/:volunteerId",
    name: "volunteer-details",
    meta: { onlyAdmin: true },
    component: () => import(/* webpackChunkName: "application" */ "./VolunteerDetailView.vue"),
  },
  {
    path: "/volunteer-edit/:volunteerId",
    name: "volunteer-edit",
    meta: { onlyAdmin: true },
    component: () => import(/* webpackChunkName: "application" */ "./VolunteerEditView.vue"),
  },
];
