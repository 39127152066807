





























import { Component, Prop, Watch } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";

@Component({
  components: {},
})
export default class ConfirmCpt extends AppVue {
  @Prop({ default: false }) showDialog: boolean;
  @Prop({ default: "Are you sure?" }) title: string;
  @Prop() message: string;

  @Watch("showDialog")
  onShowDialogChanged() {
    this.internalShowDialog = this.showDialog;
  }

  internalShowDialog = false;

  async created() {
    this.internalShowDialog = this.showDialog;
  }

  close(result: boolean) {
    this.internalShowDialog = false;
    this.$emit("close", result);
  }
}
