export const groupsRoutes = [
  {
    path: "/mentor-groups",
    name: "mentor-groups",
    meta: { onlyAdmin: true },
    component: () => import(/* webpackChunkName: "groups" */ "./MentorGroupsView.vue"),
  },
  {
    path: "/mentor-groups/print",
    name: "mentor-groups-print",
    meta: { onlyAdmin: true },
    component: () => import(/* webpackChunkName: "groups" */ "./GroupsPrintoutView.vue"),
  },
  {
    path: "/my-buddies",
    name: "my-buddies",
    component: () => import(/* webpackChunkName: "groups" */ "./MyBuddiesView.vue"),
  },
  {
    path: "/my-mentor-group",
    name: "my-mentor-group",
    component: () => import(/* webpackChunkName: "groups" */ "./MyMentorGroupView.vue"),
  },
];
