import { Vue } from "vue-property-decorator";
import axios, { AxiosRequestConfig } from "axios";
import { EmailModel } from "@/core/models";
import { EmailMessageModel } from "@/core/webapi";
import { ApiService } from "@/core/services";
import { AxiosFactory, HttpService } from "./common";

export const EmailingProvider = new Vue({
  methods: {
    async sendEmail(from: string, model: EmailModel) {
      const config: AxiosRequestConfig = AxiosFactory.createConfig();
      const resp = await ApiService.email().getEmailToken();
      config.headers.Authorization = `Bearer ${resp.data}`;
      const axiosInstance = axios.create(config);
      const url = `${process.env.VUE_APP_GRAPH_MICROSOFT}${from}/sendMail`;
      return axiosInstance.post(url, model);
    },
    async sendEmailBackend(model: EmailMessageModel) {
      return await HttpService.post("api/v1/email/send", model);
    },
  },
});
