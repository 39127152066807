import { Method, AxiosRequestConfig } from "axios";
import _ from "lodash";

import {
  Map,
  Validatable,
  PagedCollectionFilter,
  PagedCollection,
} from "@/core/models";
import { AxiosFactory } from "@/core/services/common";

/**
 * Used for POST / PUT requests with Validatable models. Will automatically resolve
 * model state so it could be used by our custom input components.
 */
function modelRequest(url: string, verb: Method, model: Validatable) {
  model.modelState = {} as Map<string[]>;
  /* eslint-disable */
  const promise = AxiosFactory.create().request({ url, method: verb, data: model });
  promise.then(
    () => {
      return;
    },
    (error: any) => {
      if (error.response && error.response.data) {
        model.modelState = error.response.data.modelState;
      }
    },
  );
  /* eslint-enable */
  return promise;
}

export const HttpService = {
  // Axios wrapper methods (mainly for auth purposes)
  getUri: async (config?: AxiosRequestConfig) => AxiosFactory.create().getUri(config),
  request: async (config: AxiosRequestConfig) => AxiosFactory.create().request(config),
  get: async (url: string, config?: AxiosRequestConfig) => AxiosFactory.create().get(url, config),
  delete: async (url: string,
    config?: AxiosRequestConfig) => AxiosFactory.create().delete(url, config),
  head: async (url: string, config?: AxiosRequestConfig) => AxiosFactory.create().head(url, config),
  post: async (url: string,
    data?: any,
    config?: AxiosRequestConfig) => AxiosFactory.create().post(url, data, config),
  put: async (url: string,
    data?: any,
    config?: AxiosRequestConfig) => AxiosFactory.create().put(url, data, config),
  patch: async (url: string,
    data?: any,
    config?: AxiosRequestConfig) => AxiosFactory.create().patch(url, data, config),

  // Custom methods for saving time
  async postModel<T>(url: string, model: Validatable) {
    const response = await modelRequest(url, "POST", model);
    return response.data as T;
  },
  async putModel<T>(url: string, model: Validatable) {
    const response = await modelRequest(url, "PUT", model);
    return response.data as T;
  },
  /**
   * Fetches data from the server and extracts the data from
   * the response as a strongly typed object.
   */
  async getData<T>(url: string, config?: AxiosRequestConfig) {
    const response = await AxiosFactory.create().get(url, config);
    return response.data as T;
  },
  /**
   * Wrapper around getData method, specifically designed for paged filtering.
   * Returns a strongly typed PagedCollection of data.
   */
  filterData<T>(url: string, filter: PagedCollectionFilter) {
    return this.getData<PagedCollection<T>>(url, { params: filter });
  },
  /**
   * Wrapper around filterData method, specifically designed find input boxes
   * such as finding a user. Ensures search string exists, defaults certain
   * filter properties to get all available results. Allows filter overriding.
   * Returns a strongly typed array of result data.
   */
  /* eslint-disable */
  async find<T>(url: string, search: string, extraParams: object = {}) {
    /* eslint-enable */
    if (!search || search.length < 3) {
      return [];
    }

    const params = _.extend(
      {
        page: 1,
        pageSize: 0,
        search,
      },
      extraParams,
    );

    const result = await HttpService.filterData<T>(url, params);

    return result.items;
  },
};
