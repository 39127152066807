import { Vue } from "vue-property-decorator";
import { HttpService } from "./common";

export const TrainingService = new Vue({
  methods: {
    async validateTrainingResultsImporting(model: FormData) {
      return await HttpService.post("api/v1/training/results/import/validate", model);
    },
    async importTrainingResults(academicYearId: number, model: FormData) {
      return await HttpService.post(`api/v1/training/results/import/${academicYearId}`, model);
    }
  },
});
