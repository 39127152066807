















import { Component, Prop } from "vue-property-decorator";
import { BaseInputComponent, InputFieldCpt } from ".";

@Component({
  components: {
    InputFieldCpt,
  },
})
export default class InputNumberCpt extends BaseInputComponent {
  @Prop() autofocus?: boolean;
  @Prop({ default: null }) max?: number;
  @Prop({ default: null }) min?: number;
  @Prop({ default: false }) clearable: boolean;

  onChangedInternal(value: any) {
    const current = Number((this.model as any)[this.prop]);
    if (this.min !== null && current < this.min) {
      (this.model as any)[this.prop] = this.min;
      this.onChange(this.min);
      return;
    }

    if (this.max !== null && current > this.max) {
      (this.model as any)[this.prop] = this.max;
      this.onChange(this.max);
      return;
    }

    this.onChange(value);
  }
  clearValue() {
    if (this.clearable) {
      this.$emit("clear");
    }
  }
}
