













import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { InputFieldCpt } from "@/core/components";
import { GridModel } from "@/core/models";

@Component({
  components: {
    InputFieldCpt,
  },
})
export default class GridSearchCpt extends Vue {
  @Prop() grid: GridModel<any>;
  @Prop({ default: "Search" }) placeholder?: string;
  @Prop({ default: null }) hint?: string;
  search = "";
  debounce: number | undefined;

  internalGrid = new GridModel<any>();

  @Watch("grid", { deep: true })
  onGridChanged() {
    this.internalGrid = this.grid;
  }

  async created() {
    if (this.grid) {
      this.search = this.grid.filter.search;
      this.onGridChanged();
    }
  }

  onChange() {
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this.grid.onSearch(this.internalGrid.filter.search);
    }, 350);
  }

  onClear() {
    this.grid.onSearch("");
  }
}
