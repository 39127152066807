
import { Prop } from "vue-property-decorator";
import { BaseInputComponent } from ".";

export default class BaseSelectableInputComponent extends BaseInputComponent {
  @Prop() lookups: any[];
  @Prop({ default: "id" }) lookupIdProp: string;
  @Prop({ default: "value" }) lookupTextProp: string;
  // eslint-disable-next-line
  @Prop() getLookupText?: (lookup: any) => string;

  /**
   * This allows custom lookup text method.
   */
  getLookupItemText(lookup: any) {
    if (this.getLookupText) {
      return this.getLookupText(lookup);
    }

    return lookup.text || lookup.name || lookup[this.lookupTextProp];
  }
}
