




























































import AppVue from "@/AppVue.vue";
import { Component } from "vue-property-decorator";

import { ButtonCpt } from "@/core/components/common";
import { ApiService, AccountService, NotificationProvider } from "@/core/services";

@Component({
  components: {
    ButtonCpt,
  },
})
export default class HeaderCpt extends AppVue {
  users = [
    { id: 2, caption: "Kit AD" },
    { id: 3, caption: "Kit ST" },
    { id: 4, caption: "Kit VT" },
    { id: 5, caption: "Danilo AD" },
    { id: 6, caption: "Danilo ST" },
    { id: 7, caption: "Danilo VT" },
    { id: 8, caption: "Trevor AD" },
    { id: 9, caption: "Martin AD" },
  ];

  async created() {
    this.$eventHub.$on("USER_UPDATED", () => {
      this.$forceUpdate();
    });
  }

  get shouldShowLogin() {
    return this.$route.path.indexOf("/login") < 0;
  }

  closeMenu() {
    this.$emit("close-menu");
  }

  async login() {
    await this.$msal.login();
  }

  async logout() {
    await this.$msal.logout();
  }

  get showAccountSwitcher() {
    // The first 9 accoutns in the DB are reserved for DT
    return this.currentUserId <= 9 && (this.isDevEnv || this.isTestEnv);
  }

  async loginAs(userId: number) {
    try {
      const response = await ApiService.account().switchUser(userId);
      AccountService.setToken(response.data);
      this.$router.go(undefined); // To enforce a redirect after USER_UPDATED
    } catch (error) {
      const message = error?.response?.data?.message;
      if (message) {
        NotificationProvider.error(message);
      }
    }
  }
}
