import { Vue } from "vue-property-decorator";
import _ from "lodash";

import {
  Map,
  GridModel,
  ElSortParams,
  PagedCollectionFilter,
  PagedCollection,
} from "@/core/models";

export const GridService = new Vue({
  methods: {
    /**
     * Builds the GridModel - binds filter, creates a default collection,
     * binds all the grid event handlers to a single object.
     * @param filter
     */
    gridFactory<T>(filter: PagedCollectionFilter) {
      const collection: PagedCollection<T> = {
        pageCount: null,
        items: null,
      } as any;

      const grid: GridModel<T> = {
        filter,
        collection,

        onSearch: (searchClause?: string) => this.onSearch(filter, searchClause),
        onFilter: () => this.onFilter(filter),
        clearSearch: () => this.clearSearch(filter),
        sort: (params: ElSortParams) => this.sort(filter, params),
        goToPage: (page: number) => this.goToPage(filter, page),

        // The next 6 are currently not in use because the Element UI
        // is smart enough to handle all this on it's own, but if we change
        // the UI toolkit, these will maybe needed as well
        prev: () => this.prev(filter),
        next: () => this.next(filter, collection),
        first: () => this.first(filter),
        last: () => this.last(filter, collection),
        isFirstPage: () => this.isFirstPage(filter),
        isLastPage: () => this.isLastPage(filter, collection),
      };

      return grid;
    },

    /**
     * Builds the paged collection filter based on current route query params.
     */
    pageQueryParamsFactory(queryParams: Map<any>, initFilter?: PagedCollectionFilter) {
      // These are standard, expected params and defaults need to be set
      const filter: PagedCollectionFilter = {
        page: queryParams.page ? Number(queryParams.page) : 1,
        pageSize: queryParams.pageSize ? Number(queryParams.pageSize) : 20,
        search: queryParams.search || "",
        sortBy: queryParams.sortBy || "",
        sortOperator: queryParams.sortOperator || "",
      };

      // Map any other dynamic params
      _.each(queryParams, (param, paramName) => {
        if (!(filter as any)[paramName]) {
          (filter as any)[paramName] = Number.isNaN(param) ? param : Number(param);
        }
      });

      if (initFilter) {
        return _.extend(filter, initFilter);
      }

      return filter;
    },

    /**
     * On search value changed event handler.
     * @param filter - Current grid collection filter.
     * @param searchClause - New search value.
     */
    onSearch(filter: PagedCollectionFilter, searchClause?: string) {
      filter.page = 1;
      filter.search = searchClause;

      if (!filter.search) {
        delete filter.search;
      }

      this.$eventHub.$emit("GRID_FILTER_UPDATED");
    },

    onFilter(filter: PagedCollectionFilter) {
      filter.page = 1;
      // No need to set or un-set the value since the model
      // is directly bound to the filter dropdown

      this.$eventHub.$emit("GRID_FILTER_UPDATED");
    },

    /**
     * Clears grid collection filter search value.
     * @param filter - Current grid collection filter.
     * @param nextFilter - Next grid collection filter callback handler.
     */
    clearSearch(filter: PagedCollectionFilter) {
      filter.page = 1;
      filter.search = "";

      this.$eventHub.$emit("GRID_FILTER_UPDATED");
    },

    /**
     * On grid sort changed event handler.
     * @param filter - Current grid collection filter.
     * @param sortBy - Sort params.
     */
    sort(filter: PagedCollectionFilter, params: ElSortParams) {
      filter.sortBy = params.prop;
      filter.sortOperator = params.order;

      this.$eventHub.$emit("GRID_FILTER_UPDATED");
    },

    /**
     * On grid specific page selected
     * @param filter - Current grid collection filter.
     * @param page Page to go to.
     */
    goToPage(filter: PagedCollectionFilter, page: number) {
      filter.page = page;
      this.$eventHub.$emit("GRID_FILTER_UPDATED");
    },

    /**
     * On grid previous page event handler.
     * @param filter - Current grid collection filter.
     */
    prev(filter: PagedCollectionFilter) {
      if (!filter.page) {
        filter.page = 1;
      }

      filter.page--;

      if (filter.page < 1) {
        filter.page = 1;
      }

      this.$eventHub.$emit("GRID_FILTER_UPDATED");
    },

    /**
     * On grid next page event handler.
     * @param filter - Current grid collection filter.
     * @param collection - Current grid collection model.
     */
    next(filter: PagedCollectionFilter, collection: PagedCollection<any>) {
      if (!filter.page) {
        filter.page = 1;
      }

      filter.page++;

      if (filter.page > collection.pageCount) {
        filter.page = collection.pageCount;
      }

      this.$eventHub.$emit("GRID_FILTER_UPDATED");
    },

    /**
     * On grid first page event handler.
     * @param filter - Current grid collection filter.
     */
    first(filter: PagedCollectionFilter) {
      filter.page = 1;
      this.$eventHub.$emit("GRID_FILTER_UPDATED");
    },

    /**
     * On grid last page event handler.
     * @param filter - Current grid collection filter.
     */
    last(filter: PagedCollectionFilter, collection: PagedCollection<any>) {
      filter.page = collection.pageCount;
      this.$eventHub.$emit("GRID_FILTER_UPDATED");
    },

    /**
     * Checks if the grid is currently on the first page of data.
     * @param filter - Current grid collection filter.
     */
    isFirstPage(filter: PagedCollectionFilter) {
      return Number(filter.page) === 1;
    },

    /**
     * Checks if the grid is currently on the last page of data.
     * @param filter - Current grid collection filter.
     * @param collection - Current grid collection model.
     */
    isLastPage(filter: PagedCollectionFilter, collection: PagedCollection<any>) {
      return Number(filter.page) === collection.pageCount;
    },
  },
});
