













import { Vue, Component, Prop } from "vue-property-decorator";
import { SimpleLookupModel } from "@/core/webapi";
import { GridModel } from "@/core/models";

@Component
export default class GridSelectFilterCpt extends Vue {
  @Prop() grid: GridModel<any>;
  @Prop() prop: string;
  @Prop({ default: true }) clearable: boolean;
  @Prop() lookups: SimpleLookupModel[];
  @Prop({ default: "FILTER PLACEHOLDER" }) placeholder: string;

  // eslint-disable-next-line
  onChange(value: any) {
    this.$forceUpdate();
    this.grid.onFilter();
  }
}
