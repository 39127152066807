



















import { Component } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";

@Component({
  components: {},
})
export default class OverlayCpt extends AppVue {
  overlay = false;
  text = "";

  async created() {
    this.$eventHub.$off("OVERLAY-SHOW");

    // On grid interaction, update the route and re-fetch data
    this.$eventHub.$on("OVERLAY-SHOW", (overlay: boolean, text: string) => {
      this.overlay = overlay;
      this.text = text;
      this.$forceUpdate();
    });
  }
}
