










import { Component } from "vue-property-decorator";
import { BaseInputComponent } from ".";

@Component({
  components: {},
})
export default class InputCheckboxCpt extends BaseInputComponent {
  onChangedInternal(value: any) {
    this.onChange(value);
  }
}
