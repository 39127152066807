














import { Component, Vue, Prop } from "vue-property-decorator";

import { Validatable } from "@/core/models";

@Component
export default class ValidationSummaryCpt extends Vue {
  @Prop() model: Validatable;

  get anonymousErrors() {
    if (!this.model.modelState || !this.model.modelState[""]) {
      return [];
    }

    return this.model.modelState[""];
  }
}
