import Vue from "vue";
import VueRouter from "vue-router";
import { AccountService } from "@/core/services";
import { homeRoutes } from "@/modules/home/routes";
import { authRoutes } from "@/modules/auth/routes";
import { userRoutes } from "@/modules/user/routes";
import { applicationRoutes } from "@/modules/application/routes";
import { groupsRoutes } from "@/modules/groups/routes";
import { trainingRoutes } from "@/modules/training/routes";
import { menteeRoutes } from "@/modules/mentee/routes";
import { azurePermissionsRoutes } from "@/modules/azure-permissions/routes";
import { adminRoutes } from "@/modules/admin/routes";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    ...homeRoutes,
    ...authRoutes,
    ...userRoutes,
    ...applicationRoutes,
    ...groupsRoutes,
    ...trainingRoutes,
    ...menteeRoutes,
    ...azurePermissionsRoutes,
    ...adminRoutes,
  ],
});

const redirectVolunteer = (next: any) => {
  if (AccountService.enforceApplication()) {
    next("/application");
    return;
  }

  next("/user/training");
};

router.beforeEach((to, from, next) => {
  const allowAnonymous = to.matched.some(record => record.meta.allowAnonymous);
  const isAuthenticated = AccountService.isAuthenticated();

  if (!allowAnonymous) {
    if (isAuthenticated) {
      // only admin route
      const onlyAdmin = to.matched.some(record => record.meta.onlyAdmin);
      if (onlyAdmin && !AccountService.isStaffOrAdmin()) {
        redirectVolunteer(next);
        return;
      }

      // only volunteer route
      const onlyVolunteer = to.matched.some(record => record.meta.onlyVolunteer);
      if (onlyVolunteer && !AccountService.isVolunteer()) {
        next("/dashboard");
        return;
      }

      // If user is an authenticated volunteer but didn't fill the application, form - enforce the form.
      if (AccountService.isVolunteer() && AccountService.enforceApplication() && to.name !== "application") {
        next("/application");
        return;
      }
    } else {
      // If a non-anonymous route and user not authenticated, redirect to login.
      next("/welcome");
      return;
    }
  }

  // If anonymous route, and user authenticated, redirect to home, don't show him the anonymous route.
  // Transition to a route without name means that the route has not been recognized.
  // Check the spelling of the route. In the meantime, redirect to default.
  // if volunteer check if it has finished the application if not redirect to user trainig
  if (isAuthenticated && (!to.name || allowAnonymous)) {
    if (AccountService.isVolunteer()) {
      redirectVolunteer(next);
    }

    next("/dashboard");
    return;
  }

  next();
});

export default router;
