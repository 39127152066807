























































































































































































import AppVue from "@/AppVue.vue";
import { ApiService } from "@/core/services";
import { AcademicYearModel, UserLookups } from "@/core/webapi";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class SidebarCpt extends AppVue {
  @Prop({ required: true }) drawer: boolean;

  internalDrawer = true;
  availableAcademicYear: AcademicYearModel = null;
  volunteerLookups = new UserLookups({
    academicYears: [],
  });

  async created() {
    this.$eventHub.$on("USER_UPDATED", () => {
      this.$forceUpdate();
    });

    this.$eventHub.$off("APPLICATION-WITHDRAWN");
    this.$eventHub.$on("APPLICATION-WITHDRAWN", async () => {
      await this.loadVolunteerLookups();
      await this.loadAvailableAcademicYear();
    });

    this.$eventHub.$off("APPLICATION-COMPLETE");
    this.$eventHub.$on("APPLICATION-COMPLETE", async () => {
      await this.loadVolunteerLookups();
      await this.loadAvailableAcademicYear();
    });

    await this.loadVolunteerLookups();
    await this.loadAvailableAcademicYear();
  }

  @Watch("drawer")
  onDrawerChanged() {
    this.internalDrawer = this.drawer;
  }

  get logoUrl() {
    return require("@/assets/logo.png");
  }

  get version() {
    return process.env.VUE_APP_VERSION;
  }

  get shouldShowVolunteersMenuItems() {
    return (
      this.isVolunteer &&
      this.volunteerLookups &&
      this.volunteerLookups.academicYears &&
      this.volunteerLookups.academicYears.length > 0
    );
  }

  async loadVolunteerLookups() {
    if (!this.isVolunteer) {
      return;
    }

    this.isLoading = true;
    try {
      const response = await ApiService.user().getUserLookups(false, false);
      this.volunteerLookups = response.data;
    } finally {
      this.isLoading = false;
    }
  }

  async loadAvailableAcademicYear() {
    this.availableAcademicYear = null;
    if (!this.isVolunteer) {
      return;
    }

    this.isLoading = true;
    try {
      const response = await ApiService.application().getAcademicYearAvailableForApplication();
      this.availableAcademicYear = response.data;
    } finally {
      this.isLoading = false;
    }
  }
}
