








import { Component, Prop } from "vue-property-decorator";
import { SimpleLookupModel } from "@/core/webapi";
import AppVue from "@/AppVue.vue";
import { ApplicationStatus } from "@/core/constants";

@Component({
  components: {},
})
export default class ApplicationStatusChipCpt extends AppVue {
  @Prop({ default: null }) model: SimpleLookupModel;

  getColor() {
    if (!this.model) return "";

    switch (this.model.id) {
      case ApplicationStatus.Trained:
        return "success";
      case ApplicationStatus.Withdrawn:
        return "orange";
      case ApplicationStatus.Declined:
        return "error";
      default:
        return "";
    }
  }
}
