export const userRoutes = [
  {
    path: "/user/profile",
    name: "user-profile",
    component: () => import(/* webpackChunkName: "user" */ "./UserProfileView.vue"),
  },
  {
    path: "/user/training",
    name: "user-training",
    meta: { onlyVolunteer: true },
    component: () => import(/* webpackChunkName: "user" */ "./UserTrainingView.vue"),
  },
  {
    path: "/user/withdraw",
    name: "user-withdraw",
    meta: { onlyVolunteer: true },
    component: () => import(/* webpackChunkName: "user" */ "./UserWithdrawView.vue"),
  },
  {
    path: "/user/activity",
    name: "user-activity",
    component: () => import(/* webpackChunkName: "user" */ "./UserActivityView.vue"),
  },
];
