
import { NotificationProvider } from "@/core/services/common";
import AppVue from "@/AppVue.vue";

export default abstract class BaseFormComponent extends AppVue {
  submitInProgress = false;
  formValid = false;
  abstract onSubmitForm(): Promise<void>;

  async submitForm() {
    if (!this.onSubmitForm) {
      throw new Error("onSubmitForm not implemented");
    }

    this.submitInProgress = true;

    try {
      await this.onSubmitForm();
    } finally {
      this.submitInProgress = false;
    }
  }

  protected onSuccess(route?: string, customMessage?: string) {
    NotificationProvider.success(customMessage || "Item saved.");

    if (route) {
      this.$router.push(route);
    }
  }
}
