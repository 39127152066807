





































import { Component, Prop, Watch } from "vue-property-decorator";
import moment from "moment";
import { DateTimeModel } from "@/core/models";
import { InputDateCpt, InputTimeCpt, BaseInputComponent } from ".";

@Component({
  components: {
    InputDateCpt,
    InputTimeCpt,
  },
})
export default class InputDateTimeCpt extends BaseInputComponent {
  @Prop({ default: undefined }) max: string;
  @Prop({ default: undefined }) min: string;
  @Prop({ default: "Date" }) dateLabel: string;
  @Prop({ default: "Time" }) timeLabel: string;
  @Prop({ default: false }) clearable: boolean;
  @Prop() autofocus?: boolean;

  internalDateFormat = "YYYY-MM-DD";
  internalTimeFormat = "HH:mm";
  menu = false;
  internalModel = new DateTimeModel();

  @Watch("model", { deep: true })
  onModelChanged() {
    this.setInternalModel();
  }

  created() {
    this.setInternalModel();
  }

  clearValue() {
    this.internalModel = new DateTimeModel();
    this.onChangeDateTime(this.internalModel);
    this.$emit("clear");
  }

  setInternalModel() {
    this.internalModel = this.getInternalModel();
    this.onChangeDateTime(this.internalModel);
  }

  getInternalModel() {
    const model = this.model as any;
    if (!model[this.prop]) {
      return new DateTimeModel();
    }
    const date = moment(model[this.prop]);

    return new DateTimeModel({
      date: this.getFormattedDate(date),
      time: this.getFormattedTime(date),
    });
  }

  getFormattedDate(date: moment.Moment) {
    if (!date) return "";
    return date.format(this.internalDateFormat);
  }

  getFormattedTime(date: moment.Moment) {
    if (!date) return "";
    return date.format(this.internalTimeFormat);
  }

  parseDateTime() {
    if (!this.internalModel) {
      return "";
    }

    if (!this.internalModel.date && this.internalModel.time) {
      this.internalModel.date = this.getFormattedDate(moment().local());
    } else if (this.internalModel.date && !this.internalModel.time) {
      this.internalModel.time = this.getFormattedTime(moment().local());
    }

    return `${this.internalModel.date}T${this.internalModel.time}:00`;
  }

  onChangeDateTime(value: any) {
    (this.model as any)[this.prop] = this.parseDateTime();
    this.onChange(value);
    this.$parent.$forceUpdate();
    this.menu = false;
  }
}
