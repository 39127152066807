
import _ from "lodash";

import AppVue from "@/AppVue.vue";
import { GridModel, PagedCollection, PagedCollectionFilter } from "@/core/models";
import { GridService, NotificationProvider } from "@/core/services/common";
import { AxiosPromise } from "axios";

export default abstract class BaseGridComponent<T> extends AppVue {
  grid: GridModel<T>;

  /**
   * Initializes the grid component. This method is supposed to be called from
   * the class that's implementing the BaseGridComponent class.
   * Builds a grid object that contains filter, collection and event handlers.
   *
   * @param filterMethod - Filter method to be called on filter change to fetch new data.
   * @param routeName - Route name to transition to on filter change (optional).
   * @param filter - Initial grid filter (optional, if not passed, default queryParams will be used).
   */
  protected initialize(
    // eslint-disable-next-line
    filterMethod: (filter: PagedCollectionFilter) => AxiosPromise<any>,
    routeName?: string,
    initFilter?: PagedCollectionFilter,
  ) {
    const filter = this.defaultQueryParamsFactory(initFilter);

    // Initialize the shared grid model
    this.grid = GridService.gridFactory<T>(filter);

    // Prevent duplication of events, will potentially have to
    // improve this in the future, but for now this is ok
    this.$eventHub.$off("GRID_FILTER_UPDATED");

    // On grid interaction, update the route and re-fetch data
    this.$eventHub.$on("GRID_FILTER_UPDATED", () => {
      this.updateRoute(filter, routeName);
      this.filterCollection(filterMethod, filter);
    });

    // Initialize the first fetch
    this.$eventHub.$emit("GRID_FILTER_UPDATED");
  }

  /**
   * Builds default query params filter. If any additional params need to be added, or any of the
   * default ones changed - override/extend the query params passed to the initializeGrid() in the
   * concrete grid implementation class.
   */
  private defaultQueryParamsFactory(initFilter?: PagedCollectionFilter) {
    return GridService.pageQueryParamsFactory(this.$route.query, initFilter);
  }

  /**
   * Updates the current grid collection route with currently defined query params (based on filter).
   * @param filter - Current grid collection filter.
   * @param route - Route name to transition to on filter change.
   */
  private updateRoute(filter: PagedCollectionFilter, routeName?: string) {
    if (routeName) {
      const definedParams = this.getDefinedParams(filter);

      this.$router.push({ name: routeName, query: definedParams as any }).catch(error => {
        // SEE: https://stackoverflow.com/a/59431264/413785
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      });
    }
  }

  /**
   * Handles the actual data fetching/filtering.
   * @param filterMethod - Filter method to be called on filter change to fetch new data.
   * @param filter - Current grid collection filter.
   */
  private filterCollection(
    // eslint-disable-next-line
    filterMethod: (filter: PagedCollectionFilter) => AxiosPromise<PagedCollection<T>>,
    filter: PagedCollectionFilter,
  ) {
    NotificationProvider.loadingStart("Getting data...");

    const definedParams = this.getDefinedParams(filter);

    filterMethod(definedParams)
      .then(collection => _.extend(this.grid.collection, collection.data))
      .catch(() => {
        _.extend(this.grid.collection, {
          pageCount: 0,
          totalItemCount: 0,
          items: [],
        });
      })
      .finally(() => {
        NotificationProvider.loadingStop();
        this.$eventHub.$emit("GRID_DATA_UPDATED");
        this.$forceUpdate();
      });
  }

  // eslint-disable-next-line
  private getDefinedParams(filter: PagedCollectionFilter) {
    // Only filter out params which are actually set, ignore unused ones
    return _.pickBy(this.grid.filter, p => p !== null && p !== undefined) as PagedCollectionFilter;
  }
}
