

























import { Component, Prop } from "vue-property-decorator";
import { BaseInputComponent } from ".";

@Component({
  components: {},
})
export default class InputFieldCpt extends BaseInputComponent {
  @Prop({ default: "text" }) type: string;
  @Prop() autofocus?: boolean;
  @Prop({ default: false }) clearable: boolean;
  @Prop({ default: false }) readonly: boolean;
  @Prop({ default: "" }) prependIcon: string;
  @Prop({ default: "" }) appendIcon: string;
  @Prop({ required: false, default: null }) bind: any;
  @Prop({ required: false, default: null }) on: any;

  clearValue() {
    if (this.clearable) {
      this.$emit("clear");
    }
  }

  onChangedInternal(value: any) {
    this.onChange(value);
  }
}
