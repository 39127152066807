

















import { Component, Prop } from "vue-property-decorator";
import { BooleanLookupModel } from "@/core/models";
import { BaseInputComponent, InputRadioCpt } from ".";

@Component({
  components: {
    InputRadioCpt,
  },
})
export default class InputYesNoRadioCpt extends BaseInputComponent {
  @Prop({ default: false }) row: boolean;

  internalLookups: BooleanLookupModel[] = [
    new BooleanLookupModel({
      id: true,
      text: "Yes",
    }),
    new BooleanLookupModel({
      id: false,
      text: "No",
    }),
  ];

  onChangedInternal(value: any) {
    this.onChange(value);
  }
}
