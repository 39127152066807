













import { Component, Prop, Watch } from "vue-property-decorator";
import moment from "moment";
import AppVue from "@/AppVue.vue";
import { InputAutocompleteCpt } from "@/core/components/common/form";

@Component({
  components: {
    InputAutocompleteCpt,
  },
})
export default class AcademicYearSelectCpt extends AppVue {
  @Prop() lookups: any[];
  @Prop() model: any;
  @Prop() prop: string;
  @Prop({ default: false }) loading: boolean;
  @Prop({ default: false }) disabled: boolean;
  @Prop({ default: "Academic Year" }) label: string;

  @Watch("lookups", { deep: true })
  onLookupsChanged() {
    this.setCurrentAcademicYear();
  }

  @Watch("currentAcademicYear")
  onAcademicYearChanged() {
    this.emitChange();
  }

  changed() {
    this.currentAcademicYear = Number(this.model[this.prop]);
    this.emitChange();
  }

  emitChange() {
    this.$emit("change", this.model);
  }

  setCurrentAcademicYear() {
    if (this.currentAcademicYear === 0) {
      this.initAcademicYear();
      // If selected academic year exists in the lookups, select it
    } else if (this.lookups.filter(item => item.id === this.currentAcademicYear).length > 0) {
      this.model[this.prop] = this.currentAcademicYear;
      this.emitChange();
      // If not, set to 0 and refresh
    } else if (this.lookups.length > 0) {
      this.currentAcademicYear = 0;
      this.setCurrentAcademicYear();
    }
  }

  initAcademicYear() {
    if (this.lookups.length > 0) {
      const today = moment().local();
      // 1st September
      const limit = moment(`${today.year()}-09-01`, "yyyy-MM-DD");
      // If today > limit startYear = currentYear else Previous year
      const startYear = today > limit ? limit.year() : limit.year() - 1;
      const endYear = startYear + 1;

      const currentAcademicYear = this.lookups.filter(item => item.startYear === startYear && item.endYear === endYear);

      if (currentAcademicYear.length > 0) {
        this.model[this.prop] = currentAcademicYear[0].id;
      } else {
        this.model[this.prop] = this.lookups[0].id;
      }
      this.changed();
    }
  }

  created() {
    this.$store.commit("initCurrentAcademicYear");
    this.setCurrentAcademicYear();
  }
}
