







import { Component, Prop } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";

@Component({
  components: {},
})
export default class YesNoChipCpt extends AppVue {
  @Prop() model: boolean;

  get color() {
    return this.model ? "success" : "error";
  }

  get text() {
    return this.model ? "Yes" : "No";
  }
}
