import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentAcademicYear: 0,
  },
  mutations: {
    initCurrentAcademicYear(state: any) {
      const currentAcademicYear = localStorage.getItem("currentAcademicYear");
      if (currentAcademicYear) {
        state.currentAcademicYear = Number(currentAcademicYear);
      }
    },
    setCurrentAcademicYear(state: any, academicYearId: number) {
      state.currentAcademicYear = academicYearId;
      localStorage.setItem("currentAcademicYear", state.currentAcademicYear);
    },
  },
  actions: {},
  modules: {},
});
