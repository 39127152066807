








import { Component, Prop } from "vue-property-decorator";
import AppVue from "@/AppVue.vue";

@Component({
  components: {},
})
export default class TrainingSessionStatusChipCpt extends AppVue {
  @Prop({ default: false }) isCancelled: boolean;
  @Prop({ default: true }) small: boolean;
}
