









































import { Component, Prop, Watch } from "vue-property-decorator";
import moment from "moment";
import { InputFieldCpt, BaseInputComponent } from ".";

@Component({
  components: { InputFieldCpt },
})
export default class InputDateCpt extends BaseInputComponent {
  @Prop({ default: "DD/MM/yyyy" }) format: string;
  @Prop({ default: undefined }) max: string;
  @Prop({ default: undefined }) min: string;
  @Prop({ default: false }) clearable: boolean;
  @Prop() autofocus?: boolean;

  internalDateFormat = "YYYY-MM-DD";
  menu = false;
  formatted = {};
  internalModel = this.getInternalModel();

  @Watch("model", { deep: true })
  onModelChanged() {
    this.internalModel = this.getInternalModel();
    this.setDateFormatted();
  }

  // NOTE: Something's messed up with typing date into input box, it just
  // acts weird so I won't enable this but it's almost implemented
  // Not continuing because Trevor said to not do it ATM
  // "readonly" prop also needs to be removed in HTML
  // @Watch("formatted.date")
  // onFormattedTimeChanged(newValue: string) {
  //   if (newValue) {
  //     const date = this.getIsoDate(moment(newValue, this.format));
  //     if (date == "Invalid date") {
  //       return;
  //     }
  //     this.internalModel = date;
  //     this.onChangeDate(this.internalModel);
  //   }
  // }

  created() {
    this.setDateFormatted();
  }

  setDateFormatted() {
    this.formatted = {
      date: this.formatDate(),
    };
  }

  clearValue() {
    if (this.clearable) {
      this.internalModel = "";
      this.onChangeDate(this.internalModel);
      this.$emit("clear");
    }
  }

  getInternalModel() {
    const model = this.model as any;
    return model[this.prop] ? this.getIsoFormattedDate(model[this.prop]) : "";
  }

  getIsoFormattedDate(date: moment.Moment) {
    if (!date) return "";
    return moment(date).format(this.internalDateFormat);
  }

  formatDate() {
    const model = this.model as any;
    if (!model[this.prop]) return null;
    return moment(model[this.prop]).format(this.format);
  }

  onChangeDate(value: any) {
    (this.model as any)[this.prop] = this.internalModel;
    this.setDateFormatted();
    this.onChange(value);
    this.$parent.$forceUpdate();
  }
}
