import { Vue } from "vue-property-decorator";
import { HttpService } from "./common";

export const MentoringService = new Vue({
  methods: {
    async validateMenteeImporting(academicYearId: number, model: FormData) {
      return await HttpService.post(`api/v1/mentoring/mentees/import/validate/${academicYearId}`, model);
    },
    async importMentees(academicYearId: number, model: FormData) {
      return await HttpService.post(`api/v1/mentoring/mentees/import/${academicYearId}`, model);
    },
  },
});
