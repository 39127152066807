
import { Vue } from "vue-property-decorator";
import { NotificationProvider } from "@/core/services/common/notification.provider";
import { AccountService } from "@/core/services";

export default class AppVue extends Vue {
  private appLoading = false;

  set currentAcademicYear(value: number) {
    this.$store.commit("setCurrentAcademicYear", value);
  }

  get currentAcademicYear() {
    return this.$store.state.currentAcademicYear;
  }

  get isLoading() {
    return this.appLoading;
  }

  set isLoading(value: boolean) {
    this.appLoading = value;
  }

  get isAuthenticated() {
    return AccountService.isAuthenticated();
  }

  get authToken() {
    return AccountService.authToken();
  }

  get currentUserId() {
    return AccountService.currentUserId();
  }

  get isAdmin() {
    return AccountService.isAdmin();
  }

  get isVolunteer() {
    return AccountService.isVolunteer();
  }

  get isStaffOrAdmin() {
    return AccountService.isStaffOrAdmin();
  }

  get isStaff() {
    return AccountService.isStaff();
  }

  get enforceApplication() {
    return AccountService.enforceApplication();
  }

  get environment() {
    return process.env.VUE_APP_ENVIRONMENT;
  }

  get isDevEnv() {
    return this.environment === "Dev";
  }

  get isTestEnv() {
    return this.environment === "Test";
  }

  get isProdEnv() {
    return this.environment === "Production";
  }

  protected notify(message: string, type: any) {
    NotificationProvider.notifyColor(message, type);
  }

  private isInRole(roleName: string) {
    return AccountService.isInRole(roleName);
  }
}
