import { Vue } from "vue-property-decorator";
import axios, { AxiosRequestConfig } from "axios";
import { AuthTokenService } from ".";
import { NotificationProvider } from "./notification.provider";

export const AxiosFactory = new Vue({
  methods: {
    create() {
      const config: AxiosRequestConfig = this.createConfig();

      if (this.$auth.isAuthenticated()) {
        config.headers.Authorization = `Bearer ${this.$auth.getToken()}`;
        AuthTokenService.emitTokenAlmostExpiredIfNecessary();
      }

      const axiosInstance = axios.create(config);

      axiosInstance.interceptors.response.use(
        response => response,
        error => this.onError(error),
      );

      return axiosInstance;
    },
    createConfig() {
      const config: AxiosRequestConfig = {
        baseURL: process.env.VUE_APP_WEBAPI_URL,
        headers: {
          Pragma: "no-cache",
        },
      };

      return config;
    },
    async onError(error: any) {
      if (error && error.response && error.response.status && error.response.status === 422) {
        return Promise.reject(error);
      }

      let message = "An error occurred.";
      // 422 is model validation error and we don't want any extra handling in that case
      if (error && error.response && error.response.status) {
        switch (error.response.status) {
          case 401: {
            if (!this.$msal.isAuthenticated()) {
              message = "Invalid session, please log in.";
              setTimeout(() => {
                this.$msal.logout();
              }, 250);
            } else {
              message = "Unauthorized action.";
            }
            break;
          }
          case 403: {
            message = "Permission denied.";
            break;
          }
          case 500: {
            if (error.response.data && error.response.data.message) {
              message = error.response.data.message;
            }
            break;
          }
          default: {
            break;
          }
        }
      }

      NotificationProvider.error(message);
      return Promise.reject(error);
    },
  },
});
