import { Vue } from "vue-property-decorator";
import { TokenResponse, LoginModel } from "@/core/webapi";
import { ApiService } from "@/core/services";
import _ from "lodash";

export const AccountService = new Vue({
  methods: {
    async login(model: LoginModel) {
      const response = await ApiService.account().login(model);
      this.setToken(response.data);

      return response;
    },
    logout() {
      if (!this.$auth.isAuthenticated()) {
        return;
      }

      this.$auth.logout();
      this.$eventHub.$emit("USER_UPDATED");
    },
    setToken(response: TokenResponse) {
      this.$auth.setToken({ data: { token: response.accessToken } }, "token");
      this.$eventHub.$emit("USER_UPDATED");
    },
    async refreshToken() {
      const response = await ApiService.account().refreshToken();
      this.setToken(response.data);

      return response;
    },
    isAuthenticated() {
      return this.$auth.isAuthenticated();
    },
    authToken() {
      return this.$auth.getPayload();
    },
    currentUserId() {
      return parseInt(this.$auth.getPayload().userId, 10);
    },
    isAdmin() {
      return this.isInRole("Admin");
    },
    isVolunteer() {
      return this.isInRole("Volunteer");
    },
    isStaffOrAdmin() {
      return this.isStaff() || this.isAdmin();
    },
    isStaff() {
      return this.isInRole("Staff");
    },
    enforceApplication() {
      const payload = this.$auth.getPayload();
      if (!payload) {
        return false;
      }

      return Boolean(JSON.parse(payload.enforceApplication));
    },
    isInRole(roleName: string) {
      const authToken = this.authToken();
      if (!this.isAuthenticated() || !authToken || !authToken.userRoles) {
        return false;
      }

      return _.includes(authToken.userRoles, roleName);
    },
  },
});
