












































import { Component, Prop, Watch } from "vue-property-decorator";
import moment from "moment";
import { BaseInputComponent, InputFieldCpt } from ".";

@Component({
  components: { InputFieldCpt },
})
export default class InputTimeCpt extends BaseInputComponent {
  @Prop({ default: undefined }) max: string;
  @Prop({ default: undefined }) min: string;
  @Prop({ default: false }) clearable: boolean;
  @Prop() autofocus?: boolean;

  menu = false;
  formatted = {};
  internalInputModel = this.getInternalModel();
  internalPickerModel = this.getInternalModel();

  @Watch("model", { deep: true })
  onModelChanged() {
    this.internalInputModel = this.getInternalModel();
    this.internalPickerModel = this.getInternalModel();
    this.setTimeFormatted();
  }

  created() {
    this.setTimeFormatted();
  }

  setTimeFormatted() {
    this.formatted = {
      time: this.formatTime(),
    };
  }

  getInternalModel() {
    const model = this.model as any;
    return model[this.prop] ? this.getTime(this.getFullDate(model[this.prop])) : "";
  }

  getTime(date: moment.Moment) {
    if (!date) return "";
    return date.format("HH:mm");
  }

  getStringFullDate(time: string) {
    return `${moment().local().format("yyyy-MM-DD")} ${time}`;
  }

  getFullDate(time: string) {
    return moment(this.getStringFullDate(time)).local();
  }

  formatTime() {
    const model = this.model as any;
    if (!model[this.prop]) return null;
    return this.getTime(this.getFullDate(model[this.prop]));
  }

  onChangeTime(value: any) {
    if (!moment(value, "HH:mm", true).isValid()) {
      return;
    }

    (this.model as any)[this.prop] = value;
    this.setTimeFormatted();
    this.onChange(value);
    this.$parent.$forceUpdate();
  }

  onFocusRemoved() {
    this.menu = false;
  }
}
